import React, {useEffect, useState} from 'react'
import styled from 'styled-components'
import {Button, CloseIcon, CustomLi, CustomSelect, CustomUl, Popup, PopupBody, PopupContainer, PopupFooter, PopupName} from "../../../../../../UIHelper";
import {colors} from "../../../../../../UIHelper/constants";
import DropDown from "../../../../../../common/dropdown";

interface IProps {
    call?: any
    togglePopup: () => void
    popupWidth?: string
    popupHeight?: string
    popupTitleText?: string
    submitHandler: ({camera, microphone}: { camera: any, microphone: any }) => void,
    sceytClient: any
    currentVideoTrack: MediaStreamTrack
    currentAudioTrack: MediaStreamTrack
}

const SettingsPopup = (
    {
        togglePopup,
        popupTitleText,
        popupHeight,
        submitHandler,
        popupWidth,
        currentVideoTrack,
        currentAudioTrack,
    }: IProps) => {

    const [selectedCamera, setSelectedCamera] = useState<MediaDeviceInfo>()
    const [allCameras, setAllCameras] = useState<any[]>()
    // @ts-ignore
    const [selectedMicrophone, setSelectedMicrophone] = useState<MediaDeviceInfo>()
    const [allMicrophone, setAllMicrophone] = useState<any[]>()
    const videoPreviewRef = React.useRef<any>(null)

    const handleSubmit = () => {
        submitHandler({camera: selectedCamera, microphone: selectedMicrophone})
        togglePopup()
    }

    const handleChangeCamera = (camera: any) => {
        setSelectedCamera(camera)
    }

    const handleClosePopup = () => {
        togglePopup()
    }

    useEffect(() => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
            console.log('devices: ', devices)
            setAllCameras(devices.filter(device => {
                if (device.kind === 'videoinput') {
                    if(device.label === currentVideoTrack.label) {
                        setSelectedCamera(device)
                    }
                    return true
                } else {
                    return false
                }
            }));
            setAllMicrophone(devices.filter(device => {
                if (device.kind === 'audiooutput') {
                    if(device.label === currentAudioTrack.label) {
                        setSelectedMicrophone(device)
                    }
                    return true
                } else {
                    return false
                }
            }));
        })
    }, [])
    useEffect(() => {
        if(selectedCamera) {
            // const stream = new MediaStream();
        }
        console.log('allCameras: ',allCameras)
    }, [selectedCamera])
    useEffect(() => {
        console.log('allMicrophone: ',allMicrophone)
    }, [allMicrophone])
    return (
        <PopupContainer>
            <Popup
                // isLoading={usersLoadingState}
                maxHeight={popupHeight || '721px'}
                width={popupWidth || '433px'}
                maxWidth={popupWidth || '433px'}
                height={popupHeight}
                padding='0'
                display='flex'
                backgroundColor={colors.white}
            >
                <PopupBody padding={12} withFooter={true}>
                    <CloseIcon color={colors.textColor2} onClick={handleClosePopup}/>

                    <PopupName>{popupTitleText}</PopupName>
                     <CameraPreviewContainer>
                         <VideoPreview ref={videoPreviewRef} />
                     </CameraPreviewContainer>
                    <CustomSelect backgroundColor={colors.white} color={colors.textColor1}>
                        <DropDown
                            withIcon
                            isSelect
                            trigger={
                                <SelectedMedia color={colors.textColor1}>
                                    {selectedCamera?.label}
                                </SelectedMedia>
                            }
                        >
                            <CustomUl>
                                {!!allCameras &&
                                    allCameras.map((camera: any) => (
                                        <CustomLi
                                            hoverBackground={colors.primaryLight}
                                            key={camera.deviceId}
                                            onClick={() => handleChangeCamera(camera)}
                                            textColor={colors.textColor1}
                                        >
                                            {camera.label}
                                        </CustomLi>
                                    ))}
                            </CustomUl>
                        </DropDown>
                    </CustomSelect>
                </PopupBody>

                <PopupFooter backgroundColor={colors.primaryLight} marginTop='auto'>

                    <Button type='button' color={colors.textColor1} backgroundColor='transparent' onClick={togglePopup}>
                        Cancel
                    </Button>
                    <Button
                        type='button'
                        color={colors.white}
                        backgroundColor={colors.primary}
                        borderRadius='8px'
                        onClick={handleSubmit}
                    >
                       Save
                    </Button>
                </PopupFooter>
            </Popup>
        </PopupContainer>
    )
}

export default SettingsPopup

const SelectedMedia = styled.span<{ color?: string }>`
    display: block;
    width: calc(100% - 22px);
    padding: 8px 10px 8px 15px;
    text-align: left;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: ${(props) => props.color || colors.textColor1};
`

const CameraPreviewContainer = styled.div`
    
`

const VideoPreview = styled.video`
    
`