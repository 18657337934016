import React from 'react';
import { Avatar } from 'sceyt-chat-react-uikit';
import Draggable from 'react-draggable';
import { ReactComponent as EndCallIcon } from '../../../../../../assets/svg/end-call.svg';
import { ReactComponent as MuteIcon } from '../../../../../../assets/svg/mute.svg';
import { ReactComponent as MinimizeCloseIcon } from '../../../../../../assets/svg/minimize-close.svg';
// @ts-ignore
import { ReactComponent as SettingsIcon } from '../../../../../../assets/svg/settingsIcon.svg';
import { CallPopupWrapper, DefaultButton } from '../../../../../../UIHelper';
import { colors } from '../../../../../../UIHelper/constants';
import styled from 'styled-components';
import ParticipantCallItem from '../participantCallItem';

const MinimizedPopup = ({
  call,
  callUsers,
  sceytClient,
  leaveCall,
  userItemWidth,
  addAudioTracks,
  handleMinimizeScreen,
}: {
  call: any;
  leaveCall: () => void;
  handleMinimizeScreen: () => void;
  callUsers: any[];
  sceytClient: any;
  addAudioTracks: boolean;
  userItemWidth: string[];
}) => {
  const handleMuteCall = () => {
    call.mute(!call.muted);
  };

  return (
    <Draggable bounds='body'>
      <CallPopupWrapper
        className='box'
        borderRadius={'16px'}
        justifyContent='space-between'
        backgroundColor={colors.textColor1}
        padding={'4px'}
        maxWidth='inherit'
        width={'320px'}
        minWidth={'320px'}
        height={'320px'}
        maxHeight='100%'
        minimize={true}
      >
        <MiniPopupIcon onClick={handleMinimizeScreen}>
          <MinimizeCloseIcon />
        </MiniPopupIcon>
        {callUsers.length > 4 ? (
          <>
            {callUsers.slice(0, 3).map((user: any, index: any) => (
              <ParticipantCallItem
                call={call}
                addAudioTracks={addAudioTracks}
                sceytClient={sceytClient}
                user={user}
                width={
                  callUsers.length === 3
                    ? index === 0
                      ? userItemWidth[0]
                      : userItemWidth[1]
                    : userItemWidth[0]
                }
                height={'calc(50% - 8px)'}
                key={user.id + index}
                margin='4px'
              />
            ))}
            <MoreParticipantsItem width={'calc(50% - 8px)'}>
              <MoreParticipants>
                {callUsers.slice(3, 6).map((user: any) => (
                  <OtherUsersAvatar key={user.id}>
                    <Avatar
                      image={user.avatarUrl}
                      size={40}
                      name={
                        user.firstName
                          ? `${user.firstName} ${user.lastName}`
                          : user.id
                      }
                    />
                  </OtherUsersAvatar>
                ))}
              </MoreParticipants>
              <MoreParticipantsText>
                {' '}
                +{callUsers.length - 3} more
              </MoreParticipantsText>
            </MoreParticipantsItem>
          </>
        ) : (
          callUsers.map((user: any, index: any) => (
            <ParticipantCallItem
              call={call}
              addAudioTracks={addAudioTracks}
              sceytClient={sceytClient}
              user={user}
              width={
                callUsers.length === 3
                  ? index === 0
                    ? userItemWidth[0]
                    : userItemWidth[1]
                  : userItemWidth[0]
              }
              height={'calc(50% - 8px)'}
              key={user.id + index}
              margin='4px'
            />
          ))
        )}
        <MiniPopupCallActions>
          <CallButtonWrapper
            iconBackground={call.muted ? '#fff' : 'rgba(98, 99, 101, 0.90)'}
            hoverIconBackground={call.muted ? '#fff' : 'rgb(75,75,75)'}
            margin='0 12px'
            onClick={handleMuteCall}
          >
            <MuteIcon />
          </CallButtonWrapper>
          <CallButtonWrapper
            iconBackground={'rgba(250, 76, 86, 0.75)'}
            hoverIconBackground={'rgba(250, 76, 86, 1)'}
            margin='0 0 0 12px'
            onClick={leaveCall}
          >
            <EndCallIcon />
          </CallButtonWrapper>
        </MiniPopupCallActions>
      </CallPopupWrapper>
    </Draggable>
  );
};

export default MinimizedPopup;

const CallButtonWrapper = styled(DefaultButton)<{
  margin?: string;
  iconBackground?: string;
  hoverIconBackground?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
  pointer-events: ${(props) => props.disabled && 'none'};

  & svg {
    color: ${(props) => props.iconBackground};
  }

  &:hover svg {
    color: ${(props) => props.hoverIconBackground};
  }
`;

const MoreParticipantsItem = styled.div<{
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  margin?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin || '4px'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height};
  color: #fff;
`;

const MoreParticipants = styled.div`
  display: flex;
`;

const MoreParticipantsText = styled.div`
  margin-top: 16px;
`;

const OtherUsersAvatar = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 -6px;
  border-radius: 50%;
  border: 2px solid ${colors.textColor1};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const MiniPopupIcon = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 2;
  cursor: pointer;
`;

const MiniPopupCallActions = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 20px;
  right: 12px;
  z-index: 2;
  cursor: pointer;
`;
