import React, { useEffect, useState } from 'react';
import styles from './style.module.css';
import { IUser } from '../../../../../types';

type CustomContactItemProps = {
  metadata: any;
  handleCreateChat: (user: IUser) => void;
};

export const CustomContactItem = ({
  metadata,
  handleCreateChat,
}: CustomContactItemProps) => {
  const [contactItem, setContactItem] = useState<{
    isWaafiNumber: boolean;
    displayName: string;
    phoneNumber: string;
  }>();

  useEffect(() => {
    setContactItem({
      isWaafiNumber:
        metadata.numbers?.length && metadata?.numbers[0]?.isWaafiNumber,
      displayName: metadata.displayName,
      phoneNumber: metadata.numbers?.length && metadata.numbers[0]?.phoneNumber,
    });
  }, [metadata]);

  const handleOpenCreateChat = (user: IUser) => {
    handleCreateChat(user);
  };

  return (
    <div className={styles.contactItemContainer}>
      <div className={styles.contactItem}>
        <div className={styles.contactItemInfo}>
          <div className={styles.contactItemAvatar}>
            <img src='/Vector.svg' alt='Avatar' className={styles.avatar} />
          </div>
          <div className={styles.contactItemNameAndNumber}>
            <div className={styles.contactItemName}>
              {contactItem?.displayName}
            </div>
            <div className={styles.contactItemNumber}>
              {contactItem?.phoneNumber}
            </div>
          </div>
        </div>

        {contactItem?.isWaafiNumber && (
          <div className={styles.isWaafiNumber}>
            <button
              className={styles.chatButton}
              onClick={() =>
                handleOpenCreateChat({
                  id: contactItem.phoneNumber,
                  firstName: contactItem.displayName,
                  lastName: '',
                  state: '',
                  presence: {
                    state: '',
                    status: '',
                    lastActiveAt: null,
                  },
                })
              }
            >
              Message
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
