import React from 'react'
import styled from "styled-components";
import {
    Popup,
    PopupContainer,
    PopupDescription,
    CloseIcon,
    PopupBody,
    SectionHeader, SectionSubHeader
} from '../../../../../../UIHelper'
import { colors } from '../../../../../../UIHelper/constants'
import { ReactComponent as AboutImage } from '../../../../../../assets/svg/about.svg'

interface IProps {
    togglePopup: () => void
}

function AboutPopup({ togglePopup }: IProps) {
    return (
        <PopupContainer>
            <Popup maxWidth='520px' minWidth='520px' padding='46px 50px 8px'>
                <CloseIcon onClick={() => togglePopup()} />
                <PopupBody padding={24} textCenter>
                    <AboutImage/>
                    <SectionHeader margin='24px 0 16px'>About WAAFI</SectionHeader>
                    <SectionSubHeader margin='0 0 16px'>WAAFI is all in-one appliacton</SectionSubHeader>
                    <PopupDescription color={colors.gray7} marginTop='0' marginBottom='24px'>
                        With WAAFI, you can send and receive
                        money, call and chat with your friends and family.
                        Hailing and paying for a ride is a breeze.
                    </PopupDescription>

                    <CopyRightText>© 2023 Powered by WAAFI SAS.</CopyRightText>
                </PopupBody>
            </Popup>
        </PopupContainer>
    )
}

export default AboutPopup

const CopyRightText = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.2px;
  margin: 0;
  color: ${colors.gray7};
`