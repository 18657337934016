import React, { useState } from 'react';
import styled from 'styled-components';
import { CreateChannel } from 'sceyt-chat-react-uikit';
import DropDown from '../../../../common/dropdown';
import { colors } from '../../../../UIHelper/constants';
import { ReactComponent as CreateChannelIcon } from '../../../../assets/svg/createChannel.svg';
import { ReactComponent as CreateGrouplIcon } from '../../../../assets/svg/createGroup.svg';
import { ReactComponent as CreateChatIcon } from '../../../../assets/svg/createChat.svg';
import { ReactComponent as AddChannelIcon } from '../../../../assets/svg/newChannelButton.svg';
import { CustomLi, CustomUl } from '../../../../UIHelper';

const CreateChannelButton: React.FC = () => {
  const [showCreateChannel, setShowCreateChannel] = useState(false);
  const [creatingChannelType, setCreatingChannelType] = useState<string>('');

  const handleOpenCreateChannel = (channelType: string) => {
    setCreatingChannelType(channelType);
    setShowCreateChannel(true);
  };

  return (
    <React.Fragment>
      <DropDown
        forceClose={showCreateChannel}
        position='center'
        trigger={
          <CreateDropdownButton
            className='custom_create_channel'
            hoverBackground={colors.primaryLight}
            leftAuto={true}
          >
            <AddChannelIcon />
          </CreateDropdownButton>
        }
      >
        <CustomUl>
          <CustomLi
            key={1}
            textColor={colors.textColor1}
            hoverBackground={colors.gray0}
            onClick={() => handleOpenCreateChannel('public')}
            iconWidth='20px'
          >
            <CreateChannelIcon />
            New channel
          </CustomLi>
          <CustomLi
            key={2}
            textColor={colors.textColor1}
            hoverBackground={colors.gray0}
            onClick={() => handleOpenCreateChannel('private')}
            iconWidth='20px'
          >
            <CreateGrouplIcon />
            New group
          </CustomLi>
          <CustomLi
            key={3}
            textColor={colors.textColor1}
            hoverBackground={colors.gray0}
            onClick={() => handleOpenCreateChannel('direct')}
            iconWidth='20px'
          >
            <CreateChatIcon />
            New chat
          </CustomLi>
        </CustomUl>
      </DropDown>

      {showCreateChannel && (
        <CreateChannel
          handleClose={() => setShowCreateChannel(false)}
          channelType={creatingChannelType}
          uriPrefixOnCreateChannel='waafi.com/'
          withoutConfig={creatingChannelType === 'direct'}
          channelTypeRequiredFieldsMap={{
            public: { uri: true, subject: true },
            private: { subject: true, members: true },
          }}
          showUri={creatingChannelType === 'public'}
        />
      )}
    </React.Fragment>
  );
};

export default CreateChannelButton;

const CreateDropdownButton = styled.div<{
  leftAuto: boolean;
  hoverBackground?: string;
  iconColor?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  line-height: 55px;
  margin-left: 12px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 0.2s;
  &:hover {
    background-color: ${(props) =>
      props.hoverBackground || colors.primaryLight};
  }
  & > svg {
    color: ${(props) => props.iconColor || colors.primary};
  }
`;
