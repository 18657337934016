import moment from 'moment';
import { IContact, IUser } from '../types';

export const isAlphanumeric = (str: string) => /[a-z]/i.test(str);

export const userLastActiveDateFormat = (date: Date) => {
  // check for the last hour
  const formattingDate = moment(date).format();
  const currentTime = moment();
  const minutesDiff = currentTime.diff(formattingDate, 'minutes');
  if (minutesDiff <= 59) {
    return `Last seen ${minutesDiff === 0 ? 1 : minutesDiff} ${
      minutesDiff > 1 ? ' minutes ago' : ' minute ago'
    }`;
  }

  // check for current day
  const startOfDay = moment().startOf('day');
  const isToday = moment(date).diff(startOfDay) >= 0;
  if (isToday) {
    return `Last seen ${moment(date).format('HH:mm')}`;
  }

  // check for yesterday
  const yesterday = moment().subtract(1, 'day').startOf('day');
  const isInYesterday = moment(date).diff(yesterday) >= 0;
  if (isInYesterday) {
    return `Last seen Yesterday at ${moment(date).format('HH:mm')}`;
  }

  // check for last week
  const isInLastWeek = moment().diff(moment(date), 'weeks') < 1;
  if (isInLastWeek) {
    return `Last seen ${moment(date).format('dddd')} at ${moment(date).format(
      'HH:mm'
    )}`;
  }

  // return formatted date
  return `Last seen ${moment(date).format('DD.MM.YY')}`;
};
export const makeUsername = (
  contact?: IContact,
  user?: IUser,
  getFirstNameOnly?: boolean
) => {
  if (user && isAlphanumeric(user.id)) {
    return user.id.toUpperCase();
  }
  return contact
    ? contact.firstName
      ? getFirstNameOnly
        ? `${contact.firstName.split(' ')[0]}`
        : `${contact.firstName.trim()} ${contact.lastName?.trim()}`.trim()
      : contact.id
    : user
    ? user.firstName
      ? getFirstNameOnly
        ? `~${user.firstName.split(' ')[0]}`
        : `~${user.firstName.trim()} ${user.lastName.trim()}`.trim()
      : user.id || 'Deleted user'
    : 'Deleted user';
};
export const safePlay = (audio: HTMLAudioElement) => {
  if (audio && typeof audio.play === 'function') {
    audio.play().catch((error) => console.error('Error playing sound:', error));
  }
};
export const isJSON = (str: any) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export const calculateRenderedImageWidth = (
  width: number,
  height: number,
  maxWidth?: number,
  maxHeight?: number
) => {
  const maxWdt = maxWidth || 420;
  const maxHg = maxHeight || 400;
  const minWidth = 165;
  const aspectRatio = width / height;
  if (aspectRatio >= maxWdt / maxHg) {
    return [
      Math.max(minWidth, Math.min(maxWdt, width)),
      Math.min(maxHg, height, maxWdt / aspectRatio) + 2
    ];
  } else {
    if (maxHg <= height) {
      return [Math.min(maxWdt, maxHg * aspectRatio), Math.min(maxHg, height)];
    } else {
      return [Math.min(maxWdt, height * aspectRatio), Math.min(maxHg, height)];
    }
  }
};
export const makeFirstById = (list: any[], id: string) => {
  const newList = [...list];
  const selfUserIndex = newList.findIndex((obj) => obj.id === id);
  if (selfUserIndex > -1) {
    const [objectToMove] = newList.splice(selfUserIndex, 1); // Remove the object
    newList.unshift(objectToMove); // Make it the first element
  }
  return newList;
};
