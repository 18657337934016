import { fetchBaseQuery } from '@reduxjs/toolkit/query';

const baseQuery = fetchBaseQuery({
  baseUrl: localStorage.getItem('waafiWebAPIBase')!,
  prepareHeaders: (headers) => {
    // @ts-ignore
    const data = localStorage.getItem('refreshToken');

    if (!data) {
      return headers;
    }

    const { chatToken } = JSON.parse(data);

    if (chatToken) {
      headers.set('authorization', `Bearer ${chatToken}`);
    }

    return headers;
  },
});

export default baseQuery;
