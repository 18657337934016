import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  CloseIcon,
  Popup,
  PopupBody,
  PopupContainer,
  PopupFooter,
  PopupName,
} from '../../../../../UIHelper';
import { colors } from '../../../../../UIHelper/constants';
import CustomCheckbox from '../../../../../common/customCheckbox';
import { USER_PRESENCE_STATUS } from '../../../../../helpers/constants';
import { makeUsername, userLastActiveDateFormat } from '../../../../../helpers';
import { Avatar } from 'sceyt-chat-react-uikit';
import { IContact, IMember, IUser } from '../../../../../types';
import { ReactComponent as CrossIcon } from '../../../../../assets/svg/cross.svg';
import { ReactComponent as SearchIcon } from '../../../../../assets/svg/search.svg';
import { useDidUpdate } from '../../../../../hooks';

interface IProps {
  call?: any;
  toggleCreatePopup: () => void;
  actionType: 'startCall' | 'addParticipant' | 'inviteUser';
  memberIds?: string[];
  selectIsRequired?: boolean;
  maxSelectionCount?: number;
  popupWidth?: string;
  popupHeight?: string;
  popupTitleText?: string;
  submitHandler: (selectedUsers: any[], videoCall?: boolean) => void;
  sceytClient: any;
  channel?: any;
  isVideoCall?: boolean;
}

const UsersPopup = ({
  sceytClient,
  channel,
  toggleCreatePopup,
  actionType,
  // getSelectedUsers,
  maxSelectionCount,
  popupTitleText,
  memberIds,
  popupHeight,
  selectIsRequired,
  submitHandler,
  popupWidth,
  isVideoCall,
}: IProps) => {
  const [userSearchValue, setUserSearchValue] = useState('');
  const [selectedMembers, setSelectedMembers] = useState<any[]>([]);
  const [contactMap, setContactMap] = useState<{ [key: string]: IContact }>({});
  const [contactList, setContactList] = useState<IContact[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<IUser[]>([]);
  const [memberList, setMemberList] = useState<IUser[]>([]);
  const [usersContHeight, setUsersContHeight] = useState(0);
  const [hasNextMembers, setHasNextMembers] = useState(true);
  const [loading, setLoading] = useState(false);

  const selectedMembersRef = useRef<any>('');
  const membersQueryRef = useRef<any>('');
  /* const handlePopupChangeToCreateChannel = () => {
      setUserSearchValue('')
      setPopupForAddMember(true)
      if (setPagination) {
        setPagination(true)
      }
    } */

  const handleUserListScroll = (event: any) => {
    if (
      !loading &&
      actionType === 'startCall' &&
      hasNextMembers &&
      !userSearchValue &&
      event.target.scrollHeight - event.target.scrollTop <=
        event.target.offsetHeight + 300
    ) {
      setLoading(true);
      membersQueryRef.current
        .loadNextPage()
        .then((membersRes: { members: IMember[]; hasNext: boolean }) => {
          setHasNextMembers(membersRes.hasNext);
          setMemberList((prevMembers) => [
            ...prevMembers,
            ...membersRes.members,
          ]);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleUserSelect = (
    event: any,
    contact: { id: string; displayName: string; avatarUrl?: string }
  ) => {
    if (
      !maxSelectionCount ||
      selectedMembers.length < maxSelectionCount ||
      !event.target.checked
    ) {
      const newSelectedMembers = [...selectedMembers];
      if (event.target.checked) {
        newSelectedMembers.push({
          id: contact.id,
          displayName: contact.displayName,
          avatarUrl: contact.avatarUrl,
        });
      } else {
        const itemToDeleteIndex = newSelectedMembers.findIndex(
          (member) => member.id === contact.id
        );
        if (itemToDeleteIndex >= 0) {
          newSelectedMembers.splice(itemToDeleteIndex, 1);
        }
      }
      setUserSearchValue('');
      setSelectedMembers(newSelectedMembers);
    }
  };

  const removeMember = (member: any) => {
    const newSelectedMembers = [...selectedMembers];

    const itemToDeleteIndex = newSelectedMembers.findIndex(
      (m) => m.id === member.id
    );
    if (itemToDeleteIndex >= 0) {
      newSelectedMembers.splice(itemToDeleteIndex, 1);
    }
    setSelectedMembers(newSelectedMembers);
  };

  const handleSubmit = () => {
    submitHandler(selectedMembers, isVideoCall);
    toggleCreatePopup();
  };
  const handleTypeSearchUser = (event: any) => {
    setUserSearchValue(event.currentTarget.value);
  };

  const handleClosePopup = () => {
    toggleCreatePopup();
  };

  useDidUpdate(() => {
    const userList: IUser[] = [];
    if (contactList && contactList.length) {
      const contactsMap: { [key: string]: IContact }[] | {} = {};
      contactList.forEach((contact) => {
        userList.push(contact.user);
        contactsMap[contact.user.id] = contact;
      });

      setContactMap(contactsMap);
    }
    if (actionType === 'addParticipant' || actionType === 'inviteUser') {
      setFilteredUsers(userList);
    } else if (channel) {
      const membersQueryBuilder = new sceytClient.MemberListQueryBuilder(
        channel.id
      );
      membersQueryBuilder
        .all()
        .byAffiliationOrder()
        .orderKeyByUsername()
        .limit(50);

      membersQueryBuilder.build().then((query: any) => {
        membersQueryRef.current = query;
        query
          .loadNextPage()
          .then((membersRes: { members: IMember[]; hasNext: boolean }) => {
            setMemberList(membersRes.members);
            setFilteredUsers(membersRes.members);
          });
      });
    }
  }, [contactList]);

  useEffect(() => {
    sceytClient.getAllContacts().then((contacts: IContact[]) => {
      setContactList(contacts);
    });
  }, []);

  useDidUpdate(() => {
    if (actionType === 'addParticipant' || actionType === 'inviteUser') {
      if (userSearchValue) {
        const filteredContacts = contactList.filter((contact: IContact) => {
          return (
            (contact.firstName &&
              contact.firstName
                .toLowerCase()
                .includes(userSearchValue.toLowerCase())) ||
            (contact.lastName &&
              contact.lastName
                .toLowerCase()
                .includes(userSearchValue.toLowerCase())) ||
            contact.id.toLowerCase().includes(userSearchValue.toLowerCase())
            // (contact.keys.length && contact.keys.find((key) =>
            // key.toLowerCase().includes(userSearchValue.toLowerCase())))
          );
        });

        setFilteredUsers(
          filteredContacts.map((contact: IContact) => contact.user)
        );
      } else {
        setFilteredUsers(contactList.map((contact: IContact) => contact.user));
      }
    } else {
      if (userSearchValue) {
        const filteredMembers = memberList.filter((member: IMember) => {
          return (
            (member.firstName &&
              member.firstName
                .toLowerCase()
                .includes(userSearchValue.toLowerCase())) ||
            (member.lastName &&
              member.lastName
                .toLowerCase()
                .includes(userSearchValue.toLowerCase())) ||
            member.id.toLowerCase().includes(userSearchValue.toLowerCase())
            // (contact.keys.length && contact.keys.find((key) =>
            // key.toLowerCase().includes(userSearchValue.toLowerCase())))
          );
        });
        setFilteredUsers(filteredMembers);
      } else {
        setFilteredUsers(memberList);
      }
    }
  }, [userSearchValue]);

  useEffect(() => {
    if (selectedMembersRef.current) {
      setUsersContHeight(selectedMembersRef.current.offsetHeight);
    } else {
      setUsersContHeight(0);
    }
  }, [selectedMembers]);

  useEffect(() => {
    /*  if (getFromContacts) {
              dispatch(getContactsAC())
          } else {
              dispatch(getUsersAC({query: userSearchValue, filter: 'all', limit: 50}))
          }*/
  }, []);
  return (
    <PopupContainer>
      <Popup
        // isLoading={usersLoadingState}
        maxHeight={popupHeight || '721px'}
        width={popupWidth || '433px'}
        maxWidth={popupWidth || '433px'}
        height={popupHeight}
        padding='0'
        display='flex'
        backgroundColor={colors.white}
      >
        <PopupBody padding={12} withFooter={true}>
          <CloseIcon color={colors.textColor2} onClick={handleClosePopup} />

          <PopupName>{popupTitleText}</PopupName>
          <SearchUserCont className='p-relative'>
            <StyledSearchIcon />
            <SearchUsersInput
              height='40px'
              onChange={handleTypeSearchUser}
              value={userSearchValue}
              placeholder='Search for users'
              type='text'
              widthBorder
              backgroundColor={colors.gray1}
              color={colors.textColor1}
            />
            {userSearchValue && (
              <ClearTypedText
                color={colors.textColor1}
                onClick={() => setUserSearchValue('')}
              />
            )}
          </SearchUserCont>
          <SelectedMembersContainer ref={selectedMembersRef}>
            {selectedMembers.map((member) => {
              return (
                <SelectedMemberBubble
                  backgroundColor={colors.gray1}
                  key={`selected-${member.id}`}
                >
                  <Avatar
                    image={member.avatarUrl}
                    name={member.displayName || member.id}
                    size={28}
                    textSize={12}
                    setDefaultAvatar
                    border={'0.5px solid rgba(0, 0, 0, 0.1)'}
                  />
                  <SelectedMemberName color={colors.textColor1}>
                    {member.displayName}
                  </SelectedMemberName>
                  <StyledSubtractSvg onClick={() => removeMember(member)} />
                </SelectedMemberBubble>
              );
            })}
          </SelectedMembersContainer>

          {/* <MembersContainer > */}
          <MembersContainer
            selectedMembersHeight={usersContHeight}
            onScroll={handleUserListScroll}
          >
            {filteredUsers.map((user: IUser) => {
              if (memberIds && memberIds.includes(user.id)) {
                return null;
              }
              const isSelected =
                selectedMembers.findIndex((member) => member.id === user.id) >=
                0;
              const memberDisplayName = makeUsername(contactMap[user.id], user);
              return (
                <ListRow hoverBackground={colors.gray1} key={user.id}>
                  <Avatar
                    image={user.avatarUrl}
                    name={
                      contactMap[user.id]
                        ? contactMap[user.id].firstName || user.id
                        : user.firstName || user.id
                    }
                    size={40}
                    textSize={16}
                    setDefaultAvatar
                  />

                  <UserNamePresence>
                    <MemberName color={colors.textColor1}>
                      {memberDisplayName}
                    </MemberName>
                    <MemberState>
                      {user.presence &&
                      user.presence.state === USER_PRESENCE_STATUS.ONLINE
                        ? 'Online'
                        : user.presence &&
                          user.presence.lastActiveAt &&
                          userLastActiveDateFormat(user.presence.lastActiveAt)}
                    </MemberState>
                  </UserNamePresence>

                  <CustomCheckbox
                    index={user.id}
                    state={isSelected}
                    backgroundColor={colors.white}
                    disabled={
                      !!(
                        maxSelectionCount &&
                        selectedMembers.length >= maxSelectionCount &&
                        !isSelected
                      )
                    }
                    checkedBackgroundColor={colors.primary}
                    onChange={(e) =>
                      handleUserSelect(e, {
                        id: user.id,
                        displayName: memberDisplayName,
                        avatarUrl: user.avatarUrl,
                      })
                    }
                    size='18px'
                  />
                </ListRow>
              );
            })}
          </MembersContainer>
          {/* </MembersContainer> */}
        </PopupBody>

        <PopupFooter backgroundColor={colors.primaryLight} marginTop='auto'>
          <Button
            type='button'
            color={colors.textColor1}
            backgroundColor='transparent'
            onClick={toggleCreatePopup}
          >
            Cancel
          </Button>
          <Button
            type='button'
            color={colors.white}
            backgroundColor={colors.primary}
            borderRadius='8px'
            disabled={selectIsRequired && selectedMembers.length === 0}
            onClick={handleSubmit}
          >
            {actionType === 'startCall' ? 'Call' : 'Add'}
          </Button>
        </PopupFooter>
      </Popup>
    </PopupContainer>
  );
};

export default UsersPopup;

const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 12px;
  min-height: 150px;
  box-sizing: border-box;
`;

const MembersContainer = styled(List)<{
  selectedMembersHeight: number;
}>`
  display: flex;
  flex-direction: column;
  //margin-top: 24px;
  position: relative;
  max-height: ${(props) =>
    `calc(100% - (${88 + props.selectedMembersHeight}px))`};
  overflow-y: auto;

  //width: calc(100% + 16px);
  padding-right: 16px;

  /* width */

  &::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #b6b6b6;
    border-radius: 4px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const SearchUserCont = styled.div`
  position: relative;
  margin: 24px 12px 0;
`;

const SearchUsersInput = styled.input<{
  widthBorder?: boolean;
  backgroundColor?: string;
  color?: string;
}>`
  height: 40px;
  width: 100%;
  font-size: 14px;
  border: ${(props) =>
    props.widthBorder ? `1px solid ${colors.gray1}` : 'none'};
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 36px;
  color: ${(props) => props.color || colors.textColor1};
  background-color: ${(props) => props.backgroundColor || colors.gray1};

  &::placeholder {
    color: ${colors.textColor2};
    font-size: 14px;
    opacity: 1;
  }

  &:focus {
    outline: none;
  }
`;

const ListRow = styled.div<{ hoverBackground?: string }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 7px 12px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s;

  &:hover {
    background-color: ${colors.gray0};
  }
`;

const UserNamePresence = styled.div`
  width: 100%;
  max-width: calc(100% - 70px);
  margin: 0 auto 0 8px;
  line-height: 10px;
`;

const MemberName = styled.h4<{ color?: string }>`
  font-style: normal;
  font-size: 15px;
  font-weight: 500;
  line-height: 16px;
  color: ${(props) => props.color || colors.textColor1};
  margin: 0;
  max-width: calc(100% - 10px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const MemberState = styled.span<{
  color?: string;
  margin?: string;
  fontSize?: string;
  lineHeight?: string;
}>`
  font-size: ${(props) => props.fontSize || '13px'};
  line-height: ${(props) => props.lineHeight || '16px'};
  letter-spacing: -0.078px;
  color: ${(props) => props.color || colors.textColor2};
  margin: ${(props) => props.margin};
`;

const SelectedMembersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  max-height: 85px;
  overflow-x: hidden;
  padding: 2px 12px 0;
  box-sizing: border-box;
  //flex: 0 0 auto;
`;

const SelectedMemberBubble = styled.div<{ backgroundColor: string }>`
  display: flex;
  justify-content: space-between;
  background: ${(props) => props.backgroundColor};
  border-radius: 16px;
  align-items: center;
  padding: 4px 10px 4px 0;
  height: 28px;
  margin: 8px 8px 0 0;
  box-sizing: border-box;
`;

const SelectedMemberName = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  margin-left: 8px;
  color: ${(props) => props.color || colors.textColor1};
`;

const StyledSubtractSvg = styled(CrossIcon)`
  cursor: pointer;
  margin-left: 4px;
  transform: translate(2px, 0);
`;

export const ClearTypedText = styled(CloseIcon)`
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
  padding: 4px;
`;
export const StyledSearchIcon = styled(SearchIcon)`
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 14px;
`;
