import React from 'react';
import { IMessage } from 'sceyt-chat-react-uikit/types';
import style from './style.module.css';
import { ReactComponent as OutgoingVoiceCall } from '../../../../../assets/svg/outgoing-voice-call.svg';
import { ReactComponent as OutgoingMissedVoiceCall } from '../../../../../assets/svg/outgoing-missed-voice-call.svg';
import { ReactComponent as IncomingVoiceCall } from '../../../../../assets/svg/incoming-voice-call.svg';
import { ReactComponent as IncomingMissedVoiceCall } from '../../../../../assets/svg/incoming-missed-voice-call.svg';

import { ReactComponent as OutgoingVideoCall } from '../../../../../assets/svg/outgoing-video-call.svg';
import { ReactComponent as OutgoingMissedVideoCall } from '../../../../../assets/svg/outgoing-missed-video-call.svg';
import { ReactComponent as IncomingVideoCall } from '../../../../../assets/svg/incoming-video-call.svg';
import { ReactComponent as IncomingMissedVideoCall } from '../../../../../assets/svg/incoming-missed-video-call.svg';

const CallLogMessage = ({ message }: { message: IMessage }) => {
  return (
    <div className={style.container}>
      <div className={style.icon}>
        {message.metadata.type === 1 ? (
          message.incoming ? (
            <>
              {message.metadata.status === 2 ? (
                <div className={style.incoming}>
                  <IncomingMissedVoiceCall className={`${style.callIcon}`} />
                </div>
              ) : (
                <div className={style.incoming}>
                  <IncomingVoiceCall className={`${style.callIcon}`} />
                </div>
              )}
            </>
          ) : (
            <>
              {message.metadata.status === 2 ? (
                <OutgoingMissedVoiceCall className={style.callIcon} />
              ) : (
                <OutgoingVoiceCall className={style.callIcon} />
              )}
            </>
          )
        ) : null}

        {/* video */}

        {message.metadata.type === 2 ? (
          message.incoming ? (
            <>
              {message.metadata.status === 2 ? (
                <div className={style.incoming}>
                  <IncomingMissedVideoCall className={`${style.callIcon}`} />
                </div>
              ) : (
                <div className={style.incoming}>
                  <IncomingVideoCall className={`${style.callIcon}`} />
                </div>
              )}
            </>
          ) : (
            <>
              {message.metadata.status === 2 ? (
                <OutgoingMissedVideoCall className={style.callIcon} />
              ) : (
                <OutgoingVideoCall className={style.callIcon} />
              )}
            </>
          )
        ) : null}
      </div>
      <div className={style.content}>
        <p className={style.message}>{message.body}</p>
        <p className={style.duration}>
          {message.metadata.status === 2
            ? 'No answer'
            : message.metadata.duration}
        </p>
      </div>
    </div>
  );
};

export default CallLogMessage;
