import React, { useEffect, useRef, useState } from 'react';
import {
  SceytChat,
  ChannelList,
  Chat,
  ChatHeader,
  MessageList,
  SendMessage,
  ChannelDetails,
  MessagesScrollToBottomButton,
} from 'sceyt-chat-react-uikit';
import SceytChatClient from 'sceyt-chat';
import styled from 'styled-components';
import LogoSrc from '../../../assets/img/logo192.png';
import { ReactComponent as LinkIcon } from '../../../assets/svg/linkIcon.svg';
import { ReactComponent as MuteNotificationsIcon } from '../../../assets/svg/mutenotifications.svg';
import { ReactComponent as UnMuteNotificationsIcon } from '../../../assets/svg/notifications.svg';
import { ReactComponent as MarkAsReadIcon } from '../../../assets/svg/markAsRead.svg';
import { ReactComponent as MarkAsUnreadIcon } from '../../../assets/svg/markAsUnRead.svg';
import { ReactComponent as EditIcon } from '../../../assets/svg/write.svg';
import { ReactComponent as PinChannelIcon } from '../../../assets/svg/pin.svg';
import { ReactComponent as UnpinChannelIcon } from '../../../assets/svg/unpin.svg';
import { ReactComponent as LinkHoverIcon } from '../../../assets/svg/linkIcon_hover.svg';
// import {ReactComponent as CloseIcon} from '../../../assets/svg/close.svg';
import { ReactComponent as FileIcon } from '../../../assets/svg/fileIcon.svg';
import { ReactComponent as FileDetailsIcon } from '../../../assets/svg/file_details.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/trash.svg';
import { ReactComponent as CopyIcon } from '../../../assets/svg/copy.svg';
import { ReactComponent as FileHoverIcon } from '../../../assets/svg/fileIcon_hover.svg';
import { ReactComponent as VideoCallIcon } from '../../../assets/svg/video-call.svg';
import { ReactComponent as VoiceCallIcon } from '../../../assets/svg/phone.svg';
import { ReactComponent as InfoIcon } from '../../../assets/svg/info.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/svg/download.svg';
import { ReactComponent as UnblockUserIcon } from '../../../assets/svg/unblockUser.svg';
import useDidUpdate from '../../../hooks/basic/useDidUpdate';
import { ReactComponent as ReplyIcon } from '../../../assets/svg/reply.svg';
import { ReactComponent as CreateChannelIcon } from '../../../assets/svg/createChannel.svg';
import { ReactComponent as CreateGroupIcon } from '../../../assets/svg/createGroup.svg';
import { ReactComponent as CreateChatIcon } from '../../../assets/svg/createChat.svg';
import { ReactComponent as UploadPhotoIcon } from '../../../assets/svg/camera.svg';
import { ReactComponent as AddAttachmentsIcon } from '../../../assets/svg/attachment.svg';
import { AWSUploader } from '../../../awsUploader';
import { setSceytClient } from '../../../helpers/client';
import Profile from './Profile';
import { colors } from '../../../UIHelper/constants';
import ChannelCustomList from './ChannelCustomList';
import { isAlphanumeric } from '../../../helpers';
import { v4 as uuidv4 } from 'uuid';
import CreateChannel from './CreateChannel';

import Calls from './Calls';
import { customColors, messagesCustomColor } from '../../../helpers/constants';
import { setActiveChannelGlobal } from '../../../helpers/activeChannel';
import { IContact } from '../../../types';
import CustomMessageItem from './CustomMessageItem';

function ChatComponent({
  chatToken,
  refreshToken,
  removeChatTokenData,
}: {
  chatToken: string;
  removeChatTokenData: () => void;
  refreshToken: () => any;
}) {
  const [client, setClient] = useState<SceytChatClient | null>(null);
  const [clientState, setClientState] = useState<any>('');
  const [activeChannel, setActiveChannel] = useState<any>();
  const [hiddenActiveCall, setHiddenActiveCall] = useState<any>(undefined);
  const [rolesMap, setRolesMap] = useState<any>();
  const [contactsMap, setContactMap] = useState<{ [key: string]: IContact }>(
    {}
  );
  const [isAdminUser, setIsAdminUser] = useState(false);
  const connectInterval = useRef<any>();
  const connectIntervalTimeout = useRef<any>();
  const connectTimeOut = useRef<any>();
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  // const [channelsSearchValue, setChannelsSearchValue] = useState<any>('');
  // const [channelGroupList, setChannelGroupList] = useState<any>([]);
  // const [activeSideBarButton, setActiveSideBarButton] = useState<string>('chat');
  const customAvatarColors: any = [
    '#FF8C8A',
    '#0DBD8B',
    '#FFA95A',
    '#7A6EF6',
    '#9565b6',
    '#6EC5F6',
  ];
  // const [tabIsActive, setTabIsActive] = useState(true)
  const tabIsActiveRef = useRef<any>(null);
  // const tokenUser = window.location.hash ? window.location.hash.split('#')[1] : 'armen'
  let hidden: any = null;
  let visibilityChange: any = null;
  if (typeof document.hidden !== 'undefined') {
    hidden = 'hidden';
    visibilityChange = 'visibilitychange';
  } else {
    // @ts-ignore
    if (typeof document.msHidden !== 'undefined') {
      hidden = 'msHidden';
      visibilityChange = 'msvisibilitychange'; // @ts-ignore
    } else if (typeof document.webkitHidden !== 'undefined') {
      hidden = 'webkitHidden';
      visibilityChange = 'webkitvisibilitychange';
    }
  }
  const handleVisibilityChange = () => {
    // @ts-ignore
    if (document[hidden]) {
      // setTabIsActive(false)
      tabIsActiveRef.current = false;
    } else {
      // setTabIsActive(true)
      tabIsActiveRef.current = true;
    }
  };

  const handleDisconnectClient = () => {
    if (client) {
      client.disconnect();
      client.removeConnectionListener('waafi_chat_connection_listener');
      client.removeChannelListener('wf_call_listeners');
    }
  };
  const handleConnectClient = (sceytClient: SceytChatClient, token: string) => {
    sceytClient
      .connect(token)
      // console.log(token)
      // sceytClient.connect('eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTgwNzA3ODMsImV4cCI6MTY5ODE1NzE4MywibmJmIjoxNjk4MDcwNzgzLCJzdWIiOiJib3JlbDIifQ.DxjEh8zt6IiBteodzjraG0vRJSz7wMAAZRFJJFk00_wLxaKfgWKhBmMOaHo3gzr0z1GBX7fy9KponSMcU2YxpXtH195FEikFFY0GqVc_wWA1vTF_exqi2WhZscHc2GR15w1Y8Mt18kTdr04NZpEs6wYFrWu2rqEBvI12KY-5Wjj4WPRe0GHtlhB-HgH9mYk-BlW4iHTmiMEHWLMoEkDISFwMCSvkYbwtlnIy4DgcZtpD58B9eGdM8qh00zl_9B9O8rGIm84tDom1cIjrr2uGco7WhHC5XWXZz59UEoN1plT3kanOXoDminLpP9fHKyEjExVNEJxz0vNmzg5JXOjitQ')
      .then(() => {
        setClient(sceytClient);
        setSceytClient(sceytClient);
        // sceytClient.setPresence('Online', '')
        setClientState('Connected');
      })
      .catch((e: any) => {
        console.log('e.code', e.code);
        console.log('e.message', e.message);
        console.log(
          'client.connectionState',
          client && client && client.connectionState
        );
        const date = new Date();
        console.info(
          `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect ... `,
          e
        );
        if (e.code === 1020 || e.code === 1021) {
          refreshToken();
        }
      });
  };
  const handleLogoutUser = () => {
    localStorage.removeItem('refreshToken');
    removeChatTokenData();
    if (client) {
      handleDisconnectClient();
    }
    setClientState('Disconnected');
  };

  const handleHideUserPresence = (user: any) => {
    return isAlphanumeric(user.id);
  };

  const handleNewMessages = (message: any) => {
    switch (message.type) {
      case 'webrtc':
      case 'service_otp':
      case 'service_token':
        return null;
      default:
        return message;
    }
  };

  const connectClient = (token: string, reconnect = false) => {
    const clientID = uuidv4();
    // const clientID = 'waafi-web-client-3412'
    const date = new Date();
    let sceytClient: any;
    if (reconnect && client) {
      handleConnectClient(client, token);
      return;
    }
    if (client) {
      handleDisconnectClient();
    }
    console.info(
      `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : connecting client with clientID: ${clientID}`
    );
    // local testing
    // sceytClient = new SceytChatClient('ws://192.168.178.62:8085/bmp', '3jrjkydywp', clientID);
    // sceytClient = new SceytChatClient('ws://192.168.178.213:8085/bmp', '3jrjkydywp', clientID);
    // sceytClient = new SceytChatClient('ws://192.168.178.216:8085/bmp', 'vd8l6eom9y', clientID);

    // staging
    // sceytClient = new SceytChatClient('https://uk-london-south-api-2-staging.waafi.com', 'vd8l6eom9y', clientID)
    // production
    const vt = localStorage.getItem('vtApiBase')
      ? JSON.parse(localStorage.getItem('vtApiBase')!)
      : {
          apiBase: 'https://uk-london-south-api-v2.waafi.com',
          appId: 'dwpe7qzx1k',
        };
    sceytClient = new SceytChatClient(vt.apiBase, vt.appId, clientID);

    // @ts-ignore
    sceytClient.setLogLevel('trace');
    // @ts-ignore
    const listener = new sceytClient.ConnectionListener();
    // @ts-ignore
    listener.onConnectionStateChanged = async (status: string) => {
      const date = new Date();
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Connection status changed: ${status}`
      );
      setClientState(status);
      if (status === 'Failed') {
        await refreshToken();
      } else if (status === 'Disconnected') {
        // await refreshToken(false)
        // setShouldRefreshToken(true)
      } else if (status === 'Connected') {
        console.log('set presence online');
        // console.log('after 5s update token online *****************')
        sceytClient.setPresence('online', sceytClient.user.presence.status);
        /*    setTimeout(() => {
                        console.log('update token **********************')
                        sceytClient.updateToken(token2)
                    }, 7000)*/
      }
    };
    // @ts-ignore
    listener.onTokenWillExpire = async (timeInterval: number) => {
      const date = new Date();
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} :The token will expire in: ${timeInterval}`
      );
      refreshToken();
    };
    // @ts-ignore
    listener.onTokenExpired = async () => {
      const date = new Date();
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : The token is expired.`
      );
      if (clientState === 'Connected') {
        refreshToken();
        // handleRefreshToken()
      } else {
        await refreshToken();
      }
    };
    sceytClient.addConnectionListener('waafi_chat_connection_listener', listener);
    const channelListener = new sceytClient.ChannelListener();

    channelListener.onTotalUnreadCountUpdated = (_totalUnreadChannelCount: number, totalUnreadMessageCount: number) => {
      setTotalUnreadCount(totalUnreadMessageCount);
    }
    /* channelListener.onSignal = async (signalMessage: any) => {
             console.log(
                 "On signal message received>>. ...  waafi ... >>: ",
                 signalMessage,
             );
         };*/
    sceytClient.addChannelListener('wf_call_listeners', channelListener);

    // eslint-disable-next-line
    sceytClient
      .connect(token)
      // console.log(token)
      // sceytClient.connect('eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTgwNzA3ODMsImV4cCI6MTY5ODE1NzE4MywibmJmIjoxNjk4MDcwNzgzLCJzdWIiOiJib3JlbDIifQ.DxjEh8zt6IiBteodzjraG0vRJSz7wMAAZRFJJFk00_wLxaKfgWKhBmMOaHo3gzr0z1GBX7fy9KponSMcU2YxpXtH195FEikFFY0GqVc_wWA1vTF_exqi2WhZscHc2GR15w1Y8Mt18kTdr04NZpEs6wYFrWu2rqEBvI12KY-5Wjj4WPRe0GHtlhB-HgH9mYk-BlW4iHTmiMEHWLMoEkDISFwMCSvkYbwtlnIy4DgcZtpD58B9eGdM8qh00zl_9B9O8rGIm84tDom1cIjrr2uGco7WhHC5XWXZz59UEoN1plT3kanOXoDminLpP9fHKyEjExVNEJxz0vNmzg5JXOjitQ')
      .then(() => {
        setClient(sceytClient);
        setSceytClient(sceytClient);
        // sceytClient.setPresence('Online', '')
        setClientState('Connected');
      })
      .catch((e: any) => {
        console.log('e.code', e.code);
        console.log('e.message', e.message);
        console.log(
          'client.connectionState',
          client && client && client.connectionState
        );
        const date = new Date();
        console.info(
          `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Error on connect ... `,
          e
        );
        if (e.code === 1020 || e.code === 1021) {
          refreshToken();
        }
      });
  };

  useDidUpdate(() => {
    if (tabIsActiveRef.current) {
      const date = new Date();
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Tab is active, connection: `,
        clientState
      );
      if (
        clientState !== 'Connected' &&
        clientState !== 'Reconnecting' &&
        !connectInterval.current
      ) {
        clearTimeout(connectTimeOut.current);
        connectTimeOut.current = setTimeout(() => {
          const date = new Date();
          console.info(
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client still not connected after tab active: call refresh token: `
          );
          refreshToken();
        }, 5000);
      }
    }
  }, [tabIsActiveRef.current]);

  useDidUpdate(() => {
    const date = new Date();
    console.info(
      `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client state changed: `,
      clientState
    );
    if (clientState === 'Disconnected') {
      console.log('Client is disconnected: call refresh token in interval');
      clearInterval(connectInterval.current);
      clearTimeout(connectIntervalTimeout.current);
      connectIntervalTimeout.current = setTimeout(() => {
        connectInterval.current = setInterval(() => {
          const date = new Date();
          console.info(
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client still not connected: call refresh token in interval `
          );
          refreshToken();
        }, 7000);
      }, 7000);
    } else if (clientState === 'Connected') {
      const date = new Date();
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client is connected: stop reconnect interval `
      );
      clearInterval(connectInterval.current);
      clearTimeout(connectIntervalTimeout.current);
      clearTimeout(connectTimeOut.current);
    }
  }, [clientState]);

  useEffect(() => {
    if (!client) {
      connectClient(chatToken);
      document.addEventListener(
        visibilityChange,
        handleVisibilityChange,
        false
      );
    }
    return () => {
      if (!client) {
        document.removeEventListener(visibilityChange, handleVisibilityChange);
        clearInterval(connectInterval.current);
        clearTimeout(connectIntervalTimeout.current);
        clearTimeout(connectTimeOut.current);
      }
    };
  }, []);

  useDidUpdate(() => {
    if (client) {
      if (clientState === 'Connected') {
        const date = new Date();
        console.info(
          `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Client is connected, update token on client`
        );
        if (client) {
          client.updateToken(chatToken);
        }
      } else {
        const date = new Date();
        console.info(
          `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Token updated, connectClient`
        );

        connectClient(chatToken, true);
      }
    } else {
      const date = new Date();
      // client.setPresence('Online', '')
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Token updated but client is not initialized, call connectClient`
      );
      connectClient(chatToken);
    }
  }, [chatToken]);

  useEffect(() => {
    if (client && clientState === 'Connected') {
      client.getAllContacts().then((contacts: IContact[]) => {
        if (contacts && contacts.length) {
          const contactsMap: { [key: string]: IContact }[] | {} = {};
          contacts.forEach((contact) => {
            // @ts-ignore
            contactsMap[contact.user.id] = contact;
          });
          setContactMap(contactsMap);
        }
      });
      client
        .getRoles()
        .then((roles: any[]) => {
          const rolesMap = {};

          roles.forEach((role: any) => {
            // @ts-ignore
            rolesMap[role.name] = role.permissions;
          });
          setRolesMap(rolesMap);
        })
        .catch((e: any) => {
          console.log('error on get roles', e);
        });
    }
  }, [client]);
  /*  useDidUpdate(() => {
          if (client && clientState === 'Connected') {

            client.getRoles().then((roles: any[]) => {
                const rolesMap = {}

                roles.forEach((role: any) => {
                    rolesMap[role.name] = role.permissions
                })
                setRolesMap(rolesMap)
            })
                .catch((e: any) => {
                    console.log('error on get roles', e)
                })

        }
    }, [client]);*/
  useEffect(() => {
    if (
      activeChannel &&
      activeChannel.peer &&
      activeChannel.peer.id &&
      isAlphanumeric(activeChannel.peer.id)
    ) {
      setIsAdminUser(true);
    } else {
      setIsAdminUser(false);
    }
    setActiveChannelGlobal(activeChannel);
  }, [activeChannel]);

  return (
    <MainContainer>
      {clientState && clientState !== 'Connected' && (
        <ConnectionStateCont>Reconnecting...</ConnectionStateCont>
      )}
      {hiddenActiveCall && (
        <HiddenCallCont onClick={() => setHiddenActiveCall(false)}>
          {hiddenActiveCall.localParticipant.videoEnabled ? (
            <VideoCallIcon />
          ) : (
            <VoiceCallIcon />
          )}{' '}
          {hiddenActiveCall.callName}
        </HiddenCallCont>
      )}
      <ChatContainer fullHeigth={!(clientState && clientState !== 'Connected')}>
        {client && (
          <SceytChat
            // autoSelectFirstChannel
            theme={{
              colors: {
                accent: {
                  light: customColors.primaryColor,
                  dark: customColors.primaryColor,
                },
              },
            }}
            client={client}
            hideUserPresence={handleHideUserPresence}
            CustomUploader={new AWSUploader() as any}
            showOnlyContactUsers
            sendAttachmentsAsSeparateMessages
            avatarColors={customAvatarColors}
            channelTypeFilter={['direct', 'private', 'public']}
            handleNewMessages={handleNewMessages}
            membersDisplayTextByChannelTypesMap={{
              public: 'subscriber',
              private: 'member',
            }}
            defaultRolesByChannelTypesMap={{
              public: 'subscriber',
              private: 'participant',
            }}
            logoSrc={LogoSrc}
          >
            <ChannelList
              List={(props) => (
                <ChannelCustomList
                  {...props}
                  totalUnreadCount={totalUnreadCount}
                />
              )}
              searchChannelsPosition={'inline'}
              searchInputBorderRadius='8px'
              searchInputBackgroundColor={customColors.primaryLight}
              // @ts-ignore
              getSelectedChannel={(channel: any) => setActiveChannel(channel)}
              // searchOption='custom'
              Profile={<Profile logoutUser={handleLogoutUser} />}
              CreateChannel={<CreateChannel />}
              newChannelIcon={<CreateChannelIcon />}
              newGroupIcon={<CreateGroupIcon />}
              newChatIcon={<CreateChatIcon />}
              uploadPhotoIcon={<UploadPhotoIcon />}
              notificationsIsMutedIcon={<MuteNotificationsIcon />}
              pinedIcon={<PinChannelIcon />}
              channelsPaddings='8px 12px'
              channelsMargin='0'
              selectedChannelBorderRadius='0'
              selectedChannelBackground={
                messagesCustomColor.ownMessageBackground
              }
              // ListItem={CustomListItem}
              // limit={10}
              sort='byLastMessage'
              // onChannelDeleted={handleChannelDeleted}
              // onChannelCreated={handleChannelCreated}
              // onAddedToChannel={handleAddedToChannel}
              // onChannelHidden={handleHideChannel}
              // showSearch={false}
              // showCreateChannelIcon={false}
              // filter={{channelType: 'Direct'}}
              // avatar={false}
              uriPrefixOnCreateChannel='waafi.com/'
            />
            <Chat>
              <ChatHeader
                infoIcon={<InfoIcon />}
                CustomActions={
                  <Calls
                    sceytClient={client}
                    hideActiveCall={hiddenActiveCall}
                    setHideActiveCall={(call) => setHiddenActiveCall(call)}
                  />
                }
              />
              <MessageList
                // fontFamily="Inter, sans-serif"
                ownMessageOnRightSide
                messageWidthPercent={80}
                messageStatusAndTimePosition='onMessage'
                // ownMessageBackground={messagesCustomColor.ownMessageBackground}
                // incomingMessageBackground={
                //   messagesCustomColor.incomingMessageBackground
                // }
                incomingRepliedMessageBackground={
                  messagesCustomColor.incomingRepliedMessageBackground
                }
                ownRepliedMessageBackground={
                  messagesCustomColor.ownRepliedMessageBackground
                }
                hoverBackground={false}
                // showSenderNameOnDirectChannel
                // showSenderNameOnOwnMessages
                // showOwnAvatar
                CustomMessageItem={(props) => (
                  <CustomMessageItem
                    {...props}
                    client={client}
                    rolesMap={rolesMap}
                    contactsMap={contactsMap}
                  />
                )}
                /*MessageActionsMenu={(
                                                    {
                                                        message,
                                                        channel,
                                                        handleSetMessageForEdit,
                                                        handleResendMessage,
                                                        handleOpenDeleteMessage,
                                                        handleOpenForwardMessage,
                                                        handleCopyMessage,
                                                        handleOpenEmojis,
                                                        handleReplyMessage,
                                                        handleSelectMessage,
                                                        isThreadMessage,
                                                        rtlDirection
                                                    }) =>
                                                    <CustomMessageActionsMenu
                                                        message={message}
                                                        channel={channel}
                                                        handleSetMessageForEdit={handleSetMessageForEdit}
                                                        handleResendMessage={handleResendMessage}
                                                        handleOpenDeleteMessage={handleOpenDeleteMessage}
                                                        handleOpenForwardMessage={handleOpenForwardMessage}
                                                        handleCopyMessage={handleCopyMessage}
                                                        handleOpenEmojis={handleOpenEmojis}
                                                        handleReplyMessage={handleReplyMessage}
                                                        isThreadMessage={isThreadMessage}
                                                        handleSelectMessage={handleSelectMessage}
                                                        rtlDirection={rtlDirection}
                                                        rolesMap={rolesMap}
                                                        client={client}
                                                    />}*/
                showMessageStatus
                messageReaction
                copyMessage
                editMessage
                deleteMessage
                replyMessage
                forwardMessage
                editIcon={<EditIcon />}
                deleteIcon={<DeleteIcon />}
                allowEditDeleteIncomingMessage={false}
                replyIconTooltipText='Quote'
                reactionIconOrder={1}
                openFrequentlyUsedReactions
                emojisContainerBorderRadius='16px'
                // separateEmojiCategoriesWithTitle
                emojisCategoryIconsPosition='top'
                messageActionIconsColor={'#818C99'}
                dateDividerTextColor={'#fff'}
                dateDividerBorder='none'
                dateDividerFontSize='13px'
                dateDividerBackgroundColor='rgba(23, 25, 28, 0.4)'
                dateDividerBorderRadius='14px'
                // reactionIcon={<ChatIcon />}
                replyIcon={<ReplyIcon />}
                copyIcon={<CopyIcon />}
                // showTopFixedDate={false}
                // inlineReactionIcon={<ReplyIcon/>}
                reactionsDisplayCount={3}
                reactionItemBorder={'none'}
                replyMessageInThread={false}
                showEachReactionCount={false}
                showTotalReactionCount
                reportMessage={false}
                // forwardMessage={false}
                newMessagesSeparatorWidth='calc(100% - 170px)'
                newMessagesSeparatorBackground={'rgba(23, 25, 28, 0.4)'}
                newMessagesSeparatorTextLeftRightSpacesWidth='12px'
                // reactionsContainerPadding={'4px 6px'}
                reactionsFontSize={'16px'}
                reactionItemPadding={'0'}
                reactionItemMargin={'0 8px 0 0'}
                reactionsContainerBoxShadow={
                  '0px 4px 8px -4px rgba(0, 0, 0, 0.12)'
                }
                reactionsContainerBorderRadius={'16px'}
                reactionsContainerBackground={colors.white}
                reactionsContainerTopPosition={'-10px'}
                reactionsDetailsPopupHeaderItemsStyle='inline'
                // newMessagesSeparatorBorder='none'
                // newMessagesSeparatorBorderRadius='0'
                newMessagesSeparatorFontSize='12px'
                newMessagesSeparatorTextColor={'#fff'}
                newMessagesSeparatorText='New Messages'
                fileAttachmentsIcon={<FileIcon />}
                fileAttachmentsBoxBackground={'#F3F5F7'}
                fileAttachmentsBoxBorder='none'
                fileAttachmentsTitleColor={colors.textColor1}
                fileAttachmentsSizeColor={'#757D8B'}
              // fileAttachmentsBoxWidth={220}
              // imageAttachmentMaxWidth={220}
              // imageAttachmentMaxHeight={220}
              // videoAttachmentMaxWidth={220}
              />
              <MessagesScrollToBottomButton />
              <SendMessage
                // CustomTypingIndicator={CustomTypingIndicator}
                attachmentIcoOrder={3}
                disabled={isAdminUser}
                margin='30px 0 0 -1px'
                // border='1px solid #EDEDED'
                border='1px solid #EDEDED'
                minHeight='48px'
                padding='0 12px 12px 0'
                inputPaddings='6px 0'
                backgroundColor='inherit'
                AddAttachmentsIcon={<AddAttachmentsIcon />}
                // handleSendMessage={handleSendMessage}
                selectedFileAttachmentsIcon={<FileIcon />}
                selectedFileAttachmentsBoxBackground={'#F3F5F7'}
                selectedFileAttachmentsBoxBorder='none'
                replyEditMessageContainerBorderRadius={'0px'}
                replyEditMessageContainerLeftPosition={'-13px'}
                replyEditMessageContainerWidth={'calc(100% - 8px)'}
                selectedFileAttachmentsTitleColor={colors.textColor1}
                selectedFileAttachmentsSizeColor={'#757D8B'}
                inputOrder={1}
                inputBackgroundColor={colors.white}
                emojiIcoOrder={0}
                replyMessageIcon={<ReplyIcon />}
                editMessageIcon={<EditIcon />}
                textSelectionBackgroundColor={'#DFF6EB'}
              // hideEmojis
              />
            </Chat>
            <ChannelDetails
              avatarAndNameDirection='row'
              showAboutChannelTitle={false}
              linkPreviewIcon={<LinkIcon />}
              linkPreviewHoverIcon={<LinkHoverIcon />}
              linkPreviewHoverBackgroundColor='#F3F5F7'
              linkPreviewColor='#757D8B'
              linkPreviewTitleColor={colors.textColor1}
              filePreviewIcon={<FileDetailsIcon />}
              filePreviewHoverIcon={<FileHoverIcon />}
              filePreviewHoverBackgroundColor='#F3F5F7'
              filePreviewTitleColor={colors.textColor1}
              filePreviewSizeColor='#757D8B'
              filePreviewDownloadIcon={<DownloadIcon />}
              leaveChannelTextColor={colors.red1}
              leaveChannelIconColor={colors.red1}
              leaveChannelOrder={4}
              deleteChannelOrder={5}
              timeOptionsToMuteNotifications={[1, 8]}
              unblockUserIcon={<UnblockUserIcon />}
              showBlockAndLeaveChannel={false}
              showBlockUser={!isAdminUser}
              showDeleteChannel={!isAdminUser}
              showChangeMemberRole={false}
              showReportChannel={false}
              showPinChannel
              muteNotificationIcon={<MuteNotificationsIcon />}
              unmuteNotificationIcon={<UnMuteNotificationsIcon />}
              markAsReadIcon={<MarkAsReadIcon />}
              markAsUnreadIcon={<MarkAsUnreadIcon />}
              pinChannelIcon={<PinChannelIcon />}
              unpinChannelIcon={<UnpinChannelIcon />}
              channelEditIcon={<EditIcon />}
              showClearHistory
              // showDeleteAllMessages
              // showClearHistoryForPrivateChannel
              // showClearHistoryForDirectChannel
              // showClearHistoryForPublicChannel
              showKickAndBlockMember={false}
            />
          </SceytChat>
        )}
      </ChatContainer>
    </MainContainer>
  );
}

export default ChatComponent;

export const MainContainer = styled.div`
  //background-color: #dfdfdf;
  //width: 100vw;
  //height: 100vh;
  /*  & #remoteVideo {
          width: 400px;
          height: 400px;
        }
      
        & #localVideo {
          width: 400px;
          height: 400px;
          margin-right: 200px;
        }*/
`;

export const ChatContainer = styled.div<any>`
  //position: fixed;
  //bottom: 10px;
  //right: 10px;
  //border: 1px solid #ccc;
  background: #fff;

  width: 100%;
  height: ${(props) => (props.fullHeigth ? '100vh' : 'calc(100vh - 28px)')};

  //width: ${(props) => (props.isOpen ? '30%' : 0)};
  //height: ${(props) => (props.isOpen ? '50%' : 0)};
  // min-width: ${(props) => (props.isOpen ? '400px' : 0)};
  transition: all 0.2s;
`;

export const ConnectionStateCont = styled.div<any>`
  background-color: #f7b626;
  padding: 6px;
  text-align: center;
  font-size: 13px;
  color: #fff;
`;

export const HiddenCallCont = styled.div<any>`
  background-color: ${colors.primary};
  padding: 6px;
  text-align: center;
  font-size: 13px;
  color: #fff;
  cursor: pointer;
  display: flex;
  gap: 12px;
  justify-content: center;
  align-items: center;

  & svg {
    color: #fff;
  }
`;
