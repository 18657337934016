export const getItem = (key: string) => {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    console.error('Error getting item from localStorage:', error);
    return null;
  }
};

export const getClientOptions = () => {
  try {
    const clientOptions = JSON.parse(getItem('clientOptions') || '{}');

    return clientOptions || {};
  } catch (error) {
    console.error('Error getting client options:', error);
    return {};
  }
};
