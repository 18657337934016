import React, { useEffect, useState } from 'react';
import Initiate from './Components/Pages/Initiate';
import SceytChat from './Components/Pages/SceytChat';
import { rootApi } from './api';
// @ts-ignore
import packageJson from '../package.json';

function App() {
  const [loading, setLoading] = useState(true);
  const [chatTokenData, setChatTokenData]: any = useState(null);
  const [networkConnection, setNetworkConnection]: any = useState('online');

  const handleRefreshToken = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    let refreshTokenData = { token: '', subscriptionId: '' };
    if (refreshToken) {
      refreshTokenData = JSON.parse(refreshToken);
    }
    const date = new Date();
    console.info(
      `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Going to refresh waafi token `
    );
    return rootApi.post(
      'chat/refresh/token',
      JSON.stringify({
        payload: {
          refreshToken: refreshTokenData.token,
          subscriptionId: refreshTokenData.subscriptionId,
        },
      })
    );
  };

  const checkRefreshToken = () => {
    const date = new Date();
    console.info(
      `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Called check refresh token, network connection state : `,
      networkConnection
    );
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      // if(networkConnection === 'online') {
      handleRefreshToken()
        .then((response) => {
          console.info(
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Update waafi token success ... `
          );
          setChatTokenData({
            token: response.data.payload.token,
            subscriptionId: response.data.payload.subscriptionId,
          });
          // setChatTokenData({token: response.data.payload.token, subscriptionId: chatTokenData.subscriptionId})
          localStorage.setItem(
            'refreshToken',
            JSON.stringify({
              token: response.data.payload.refreshToken,
              chatToken: response.data.payload.token,
              subscriptionId: response.data.payload.subscriptionId,
            })
          );
          localStorage.setItem(
            'clientOptions',
            JSON.stringify(response.data.payload.clientOptions)
          );
          // localStorage.setItem('refreshToken', JSON.stringify({token: response.data.payload.refreshToken, subscriptionId: chatTokenData.subscriptionId}))
          setLoading(false);
        })
        .catch((e) => {
          const date = new Date();
          console.error(
            `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Failed to refresh waafi token ... `,
            e
          );

          if (
            e.response &&
            e.response.data &&
            e.response.data.payload &&
            e.response.data.payload.replyCode === 7005
          ) {
            console.info(
              `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Refresh token is expired, clear tokens and go to initiate ... `
            );
            localStorage.removeItem('refreshToken');
            setChatTokenData(null);
          }
          setLoading(false);
        });
      // }
    } else {
      console.info(
        `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : No refresh token, clear tokens and go to initiate ... `
      );
      localStorage.removeItem('refreshToken');
      setChatTokenData(null);
      setLoading(false);
    }
  };
  const onlineStatusChanged = (event: Event) => {
    const date = new Date();
    console.info(
      `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Online status changed : `,
      event.type
    );
    setNetworkConnection(event.type);
  };

  useEffect(() => {
    /* if (networkConnection === 'online' && sceytClinet && ( sceytClinet.chatClient.connectStatus === 'Disconnected' || sceytClinet.chatClient.connectStatus === 'Failed') ) {
             const date = new Date()
             console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Network changed to online and client is disconnected `)
             setTimeout(() => {
                 if(sceytClinet && ( sceytClinet.chatClient.connectStatus === 'Disconnected' || sceytClinet.chatClient.connectStatus === 'Failed')) {
                     const date = new Date()
                     console.info(`${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Network changed to online and after 5s client is disconnected, going to refresh token `)
                     checkRefreshToken()
                 }
             }, 8000)
         }*/
  }, [networkConnection]);
  useEffect(() => {
    console.log('WAAFI WEB VERSION: ', packageJson.version);
    const tokenData =
      localStorage.getItem('refreshToken') &&
      JSON.parse(localStorage.getItem('refreshToken') as string);
    if (tokenData && tokenData.chatToken) {
      console.log('set chat token data .. ', tokenData);
      setChatTokenData({
        token: tokenData.chatToken,
        subscriptionId: tokenData.subscriptionId,
      });
      setLoading(false);
    } else {
      checkRefreshToken();
    }
    if (
      typeof window !== 'undefined' &&
      window != null &&
      window.addEventListener != null
    ) {
      window.addEventListener('offline', onlineStatusChanged);
      window.addEventListener('online', onlineStatusChanged);
    }
  }, []);

  useEffect(() => {
    if (chatTokenData && chatTokenData.token) {
      const currentUrl = new URL(window.location.href);
      const callbackUrl = currentUrl.searchParams.get('callbackUrl');
      if (callbackUrl) {
        window.location.href = callbackUrl;
      }
    }
  }, [chatTokenData]);

  return (
    <>
      {loading ? (
        <></>
      ) : chatTokenData && chatTokenData.token ? (
        <SceytChat
          refreshToken={checkRefreshToken}
          removeChatTokenData={() => setChatTokenData(null)}
          chatToken={chatTokenData.token}
        />
      ) : (
        <Initiate setChatToken={setChatTokenData} />
      )}
    </>
  );
}

export default App;
