import React, {useEffect, useRef} from 'react';
// @ts-ignore
import Favico from 'favico.js';
import styled from 'styled-components';
import useDidUpdate from "../../../../hooks/basic/useDidUpdate";

const favicon = new Favico({
    position: 'up'
});

function ChannelCustomList({
                               loadMoreChannels,
                               children,
                               searchValue,
                               totalUnreadCount,
                               // handleSetChannelListWithGroups
                           }: {
    channels: any[],
    activeChannel?: any,
    loadMoreChannels: () => void,
    children: any,
    searchValue?: string,
    handleSetChannelListWithGroups?: (channelListGroups: any[]) => void,
    setActiveChannel?: (channel: any) => void,
    totalUnreadCount: number
}) {
    const channelsScrollRef = useRef<HTMLInputElement>(null)
    const handleScroll = (e: any) => {
        if (e.target.scrollTop >= (e.target.scrollHeight - e.target.offsetHeight) - 200) {
            loadMoreChannels();
        }
    };

    useDidUpdate(() => {
        if (!searchValue) {
            if (channelsScrollRef.current) {
                channelsScrollRef.current.scrollTop = 0
            }
        }
    }, [searchValue])

    useEffect(() => {
        try {
            favicon.badge(totalUnreadCount);
            // @ts-ignore
            if (navigator && navigator.setAppBadge) {
                if (totalUnreadCount < 1) {
                    // Clear the badge
                    // @ts-ignore
                    navigator.clearAppBadge().catch((error: any) => {
                        console.log('failed to clear badge .. ', error)
                        // Do something with the error.
                    });
                } else {
                    // @ts-ignore
                    navigator.setAppBadge(totalUnreadCount).catch((error: any) => {
                        console.log('failed to set badge .. ', error)
                        //Do something with the error.
                    });

                }
            }
        } catch (e) {
            console.log('error setting favicon', e)
        }
    }, [totalUnreadCount])

    return (
        <CustomChannelList ref={channelsScrollRef} onScroll={handleScroll}>{children}</CustomChannelList>
    );
}

export const CustomChannelList = styled.div`
    overflow-y: auto;
    width: 360px;
    border-right: 1px solid #EDEDED;
    height: 100%;
    //min-width: 300px;
    //display: flex;
    //flex-direction: column-reverse;
`;

export default ChannelCustomList;
