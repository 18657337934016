import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const ParticipantCallItem = ({
  sceytClient,
  addAudioTracks,
  user,
}: {
  sceytClient: any;
  user: any;
  addAudioTracks: boolean;
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const videoRef = React.useRef<any>(null);
  const audioTrackId = React.useRef<any>(null);
  const videoTrackId = React.useRef<any>(null);
  const handleAddTracksToVideo = () => {
    const stream = new MediaStream();
    if (user.videoEnabled && user.videoTracks && user.videoTracks?.length > 0) {
      stream.addTrack(user.videoTracks[0]);
    }
    if (
      addAudioTracks &&
      user?.audioTracks?.length > 0 &&
      user.id !== sceytClient.user.id
    ) {
      stream.addTrack(user.audioTracks[0]);
    }
    videoRef.current.srcObject = stream;
    // }
  };
  useEffect(() => {
    if (
      user &&
      ((user.videoTracks &&
        user.videoTracks?.length > 0 &&
        user.videoTracks[0]?.id !== videoTrackId.current) ||
        (user.audioTracks &&
          user.audioTracks?.length > 0 &&
          audioTrackId.current !== user.audioTracks[0].id))
    ) {
      handleAddTracksToVideo();
    }
  }, [user]);
  useEffect(() => {
    if (addAudioTracks) {
      handleAddTracksToVideo();
    }
  }, [addAudioTracks]);

  useEffect(() => {
    setShowVideo(user.videoEnabled);
  }, [user.videoEnabled]);

  return (
    <UserVideo
      hide={!showVideo}
      ref={videoRef}
      playsInline
      autoPlay
    ></UserVideo>
  );
};

export default React.memo(ParticipantCallItem, (prevProps, nextProps) => {
  return (
    prevProps.addAudioTracks === nextProps.addAudioTracks &&
    prevProps.user.videoEnabled === nextProps.user.videoEnabled &&
    prevProps.user.videoTracks === nextProps.user.videoTracks &&
    prevProps.user.audioTracks === nextProps.user.audioTracks
  );
});

const UserVideo = styled.video<{ hide?: boolean }>`
  display: ${(props) => (props.hide ? 'none' : 'block')};
`;
