import axios from 'axios';

// Function to fetch and update baseURL
const getApiConfig = () => {
  const request = new XMLHttpRequest();
  request.open('GET', '/api/config', false); // 'false' makes the request synchronous
  request.send(null);

  if (request.status === 200) {
    const apiBase = JSON.parse(request.responseText);
    localStorage.setItem('waafiWebAPIBase', apiBase.waafiWebAPIBase);
    localStorage.setItem('vtApiBase', JSON.stringify(apiBase.vt));
    localStorage.setItem(
      'vtCallMonitoringAPIBase',
      apiBase.vtCallMonitoringAPIBase
    );
    return apiBase.ottAPIBase;
  } else {
    console.error('Error fetching base URL:', request.statusText);
    return '';
  }
};

// Fetch the base URL synchronously
const baseURL = getApiConfig();
// Create axios instance with the fetched baseURL
const rootApi = axios.create({
  baseURL,
  timeout: 30000,
});

rootApi.interceptors.request.use(
  async (request) => {
    return { ...request };
  },
  (error) => Promise.reject(error)
);

export default rootApi;
