import { Amplify, Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import {resizeImage} from "../helpers/resizeImage";
import {IAttachment, IUploader} from "../types";

// const getByKy = 'public/5bfa22ae-0709-4f38-8244-2ad74c60d6c5'
// const getByKy2 = 'public/33066323-3513-4d03-8739-fed09f3c200'
// const getByKy3 = 'public/981517d2-e0e7-4240-95aa-01bf2c9f1b38'
// const imageKy3 = 'public/5a6f71c6-0922-4e41-a8c5-98e72f1051fd'
const region = "eu-west-2"; //REGION
const bucketName = "waafi-production231511-production"; //BUCKET_NAME
// const pendingUploaders: { [key: string]: any } = {};
interface IProgress {
    loaded: number, total: number
}
interface IUploadTask {
    updateLocalFileLocation: (newPath: String) => void
    progress: (progress: IProgress) => void
    failure: (error: Error) => void
    success:(uri: string) => void
    cancel: () => void,
    stop: () => void,
    resume: () => void
}

export class AWSUploader {

    constructor() {
        Amplify.configure({
            Auth: {
                identityPoolId: 'eu-west-2:1dad5760-9c17-4578-90d5-14fdac4ce489', //REQUIRED - Amazon Cognito Identity Pool ID
                region: region, // REQUIRED - Amazon Cognito Region
                userPoolId: 'eu-west-2_WevjiDEpF', //OPTIONAL - Amazon Cognito User Pool ID
                // userPoolWebClientId: 'XX-XXXX-X_abcd1234', //OPTIONAL - Amazon Cognito Web Client ID
            },
            Storage: {
                AWSS3: {
                    bucket: bucketName, //REQUIRED -  Amazon S3 bucket name
                    region: region, //OPTIONAL -  Amazon service region
                }
            }
        });
    }

    upload =  async (attachment: IAttachment, uploadTask: IUploadTask) => {
        // console.log('called uploader with file. ', file)
        const fileKey = uuidv4();
        let resizedBlob
        let resizedFile
        if(attachment.type === 'image' && (attachment.url && attachment.url.type !== 'image/gif')) {
            resizedBlob = await resizeImage(attachment.url)
            resizedFile = new File([resizedBlob], attachment.name)
        }
        const filePath = URL.createObjectURL(resizedBlob || attachment.url)
        if(uploadTask.updateLocalFileLocation) {
            uploadTask.updateLocalFileLocation(filePath)
        }
        let fileType = attachment.url.type
        if(attachment.url.type === 'application/json') {
            fileType = ''
        }
        // pendingUploaders[attachment.attachmentId] = await Storage.put(fileKey, resizedFile || attachment.url, {
        try {
            const uploader: any = await Storage.put(fileKey, resizedFile || attachment.url, {
                resumable: true,
                contentType: fileType,
                useAccelerateEndpoint: true,
                completeCallback: (event: any) => {
                    uploadTask.success(event.key || '')
                },
                progressCallback: (progress: IUploader) => {
                    console.log(`Uploaded: ${progress.loaded / progress.total}`);
                    if(uploadTask.progress) {
                        uploadTask.progress({loaded: progress.loaded, total: progress.total})
                    }
                },
                errorCallback: (e: any) => {
                    console.log('error uploading file. ..  ', e)
                    uploadTask.failure(e)
                }
            });
            uploadTask.stop = () => {
                uploader.pause()
                return !uploader.isInProgress
            }
            uploadTask.resume = () => {
                uploader.resume();
                return uploader.isInProgress
            }
            uploadTask.cancel = () => {
                Storage.cancel(uploader)
            }
        } catch (e) {
            console.log('error uploading file. ', e)
            uploadTask.failure(e)
        }
    };

    download = (uri: string, download: boolean, progressCallback?: (progress: any) => void) => {
        return Storage.get(uri, {
            download: download,
            progressCallback: (progress: IUploader) => {
                progressCallback && progressCallback({loaded: progress.loaded, total: progress.total})
            }
        })
    };

    cancelRequest = async (request: any) => {
        const res =  await Storage.cancel(request, "download is canceled")
        console.log('res is ', res)
    };
    /*pauseUpload = () => {
        this.uploader.pause();
    }

    resumeUpload = () => {
        this.uploader.resume();
    }

    cancelUpload = () => {
        Storage.cancel(this.uploader);
    }*/
}