
export const MESSAGE_DELIVERY_STATUS = {
    PENDING: 'pending',
    SENT: 'sent',
    DELIVERED: 'received',
    READ: 'displayed'
}

export const CHANNEL_TYPE = {
    GROUP: 'group',
    BROADCAST: 'broadcast',
    DIRECT: 'direct',
    PUBLIC: 'public',
    PRIVATE: 'private'
}

export const USER_STATE = {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    DELETED: 'deleted'
}

export const USER_PRESENCE_STATUS = {
    OFFLINE: 'offline',
    ONLINE: 'online'
}

export const attachmentTypes = {
    image: 'image',
    video: 'video',
    audio: 'audio',
    file: 'file',
    link: 'link',
    voice: 'voice'
}

export const messagesCustomColor = {
    ownMessageBackground: '#DFF6EB',
    incomingMessageBackground: '#F0F2F5',
    ownRepliedMessageBackground: '#eefcf5',
    incomingRepliedMessageBackground: '#F9FAFB',
};
export const customColors = {
    defaultAvatarBackground: '#C0CAD8',
    deletedUserAvatarBackground: '#C0CAD8',
    primaryColor: '#0DBD8B',
    primaryLight: '#F0F2F5',
    textColor1: '#17191C'
}
export const MESSAGE_STATUS = {
    UNMODIFIED: 'unmodified',
    EDIT: 'edited',
    DELETE: 'deleted',
    FAILED: 'failed'
}