import styled from 'styled-components';
import React, { useState } from 'react';
import { Avatar } from 'sceyt-chat-react-uikit';
import { ReactComponent as BottomIcon } from '../../../../assets/svg/chevronBottom.svg';
import ProfileSettings from './ProfileSettings';
import { getSceytClient } from '../../../../helpers/client';

interface IProfileProps {
  logoutUser: () => void;
}

const Profile = ({ logoutUser }: IProfileProps) => {
  const client = getSceytClient();
  const [profileIsOpen, setProfileIsOpen] = useState(false);
  const [user, setUser] = useState(client.user);
  const handleOpenEditProfile = () => {
    setProfileIsOpen(!profileIsOpen);
  };

  const updateUser = (updatedUser: any) => {
    console.log('update user profile ... ', updatedUser);
    setUser(updatedUser);
  };

  return (
    <>
      <ProfileCont onClick={handleOpenEditProfile}>
        <Avatar
          image={user.avatarUrl}
          name={user.firstName || user.id}
          size={32}
          textSize={15}
          setDefaultAvatar
        />
        <BottomIcon />
      </ProfileCont>

      {profileIsOpen && (
        <ProfileSettings
          user={user}
          logoutUser={logoutUser}
          updateUser={updateUser}
          handleCloseProfile={handleOpenEditProfile}
        />
      )}
    </>
  );
};

export default Profile;

const ProfileCont = styled.div`
  display: flex;
  align-items: center;
  margin-right: 12px;
  cursor: pointer;

  & > svg {
    margin-left: 4px;
  }
`;
