import React, { useEffect } from 'react';
import { Avatar } from 'sceyt-chat-react-uikit';

import { ReactComponent as DefaultIcon } from '../../../../../assets/svg/call-avatar.svg';
import { ReactComponent as DeclineCallIcon } from '../../../../../assets/svg/decline-call.svg';
import { ReactComponent as AcceptCallIcon } from '../../../../../assets/svg/accept-call.svg';
import {
  ButtonBlock,
  DefaultButton,
  Popup,
  PopupContainer,
} from '../../../../../UIHelper';
import { colors } from '../../../../../UIHelper/constants';
import styled from 'styled-components';

const IncomingCallPopup = ({
  call,
  handleAnswerCall,
  callName,
  callAvatar,
}: {
  call: any;
  handleAnswerCall: (answer: string) => void;
  callName: string;
  callAvatar: string;
  callUsers: any[];
}) => {
  // const [p2pUser, setP2pUser] = useState<any>(null)
  /*    const [callInvitation, setCallInvitation] = useState<any>(null)
        const callClient = useRef<SceytCallClient | null>(null);*/
  const answerCall = async () => {
    handleAnswerCall('answer');
  };
  const declineCall = async () => {
    handleAnswerCall('decline');
  };
  // const callName = call.participants.map((participant: any) => participant.id).join(', ')

  /*    const handleInvitedToCall = async (call: any) => {
            console.log('invited to call', call)
            setCallInvitation({
                callId: call.id,
                sessionId: call.sessionId,
                mediaFlow: call.mediaFlow,
                participantIds: call.participants.map((p: any) => p.id),
                videoCall: true
            })
        }*/
  useEffect(() => {
    console.log(
      'call.metadata && call.metadata.csb.....>',
      call.metadata && call.metadata.csb
    );
  }, [call]);

  return (
    <PopupContainer>
      <Popup
        backgroundColor={colors.textColor1}
        padding='32px'
        maxWidth='320px'
        minWidth='320px'
      >
        <CallInfoWrapper>
          <Avatar
            size={120}
            textSize={36}
            name={callName}
            setDefaultAvatar={!(call.metadata && call.metadata.csb)}
            image={callAvatar}
            DefaultAvatar={<DefaultIcon />}
          />
          <CallName>{callName}</CallName>
          <CallType>
            Incoming {call.videoEnabled ? 'video' : 'audio'} call
          </CallType>
        </CallInfoWrapper>
        <ButtonBlock justify={'center'}>
          <CallButtonWrapper margin='0 48px 0 0 ' onClick={declineCall}>
            <DeclineCallIcon />
          </CallButtonWrapper>
          <CallButtonWrapper onClick={answerCall}>
            <AcceptCallIcon />
          </CallButtonWrapper>
        </ButtonBlock>
      </Popup>
    </PopupContainer>
  );
};

export default IncomingCallPopup;

const CallButtonWrapper = styled(DefaultButton)<{ margin?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
`;

const CallInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 32px 0 56px;
`;

const CallName = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${colors.white};
  margin: 12px 0 0;
`;

const CallType = styled.p`
  color: #b9babb;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.078px;
  margin: 0;
`;
