import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  is_closed: true,
};

export const downloadModalSlice = createSlice({
  initialState,
  name: 'download modal',
  reducers: {
    toggle: (state) => {
      state.is_closed = !state.is_closed;
    },
  },
});

export const { toggle } = downloadModalSlice.actions;
