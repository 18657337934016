import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { Avatar } from 'sceyt-chat-react-uikit';
import { ReactComponent as ArrowLeft } from '../../../../../assets/svg/arrowLeft.svg';
import { ReactComponent as InfoIcon } from '../../../../../assets/svg/info.svg';
import { ReactComponent as LogoutIcon } from '../../../../../assets/svg/leave.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/svg/edit.svg';
import { colors } from '../../../../../UIHelper/constants';
import { CustomLi, CustomUl } from '../../../../../UIHelper';
import EditProfile from './EditProfile';
import AboutPopup from './AboutPopup';
import { rootApi } from '../../../../../api';

interface IChannelTabsProps {
  user: any;
  handleCloseProfile: () => void;
  updateUser: (updatedUser: any) => void;
  logoutUser: () => void;
}

const settingsPages = {
  profile: 'Profile',
  notifications: 'Notifications',
  about: 'About',
};

const ProfileSettings = ({
  handleCloseProfile,
  user,
  updateUser,
  logoutUser,
}: IChannelTabsProps) => {
  const [editProfileIsOpen, setEditProfileIsOpen] = useState(false);
  // const [notificationsSettingsIsOpen, setNotificationsSettingsIsOpen] = useState(false)
  const [aboutPopupIsOpen, setAboutPopupIsOpen] = useState(false);
  const [activeSettingPage, setActiveSettingPage] = useState('');
  const [shouldLogout, setShouldLogout] = useState(false);

  const handleOpenEditProfile = () => {
    setActiveSettingPage(editProfileIsOpen ? '' : settingsPages.profile);
    setEditProfileIsOpen(!editProfileIsOpen);
  };

  const handleOpenNotificationsSettings = () => {
    // setActiveSettingPage(notificationsSettingsIsOpen ? '' : settingsPages.notifications)
    // setNotificationsSettingsIsOpen(!notificationsSettingsIsOpen)
  };
  const handleLogout = () => {
    setShouldLogout(true);
  };

  useEffect(() => {
    if (shouldLogout) {
      // @ts-ignore
      const refreshTokenData: any = JSON.parse(
        localStorage.getItem('refreshToken')!
      );
      rootApi
        .post('chat/web/logout', {
          payload: {
            refreshToken: refreshTokenData.token,
            subscriptionId: refreshTokenData.subscriptionId,
          },
        })
        .then(() => {
          console.log('logout success');
          logoutUser();
        })
        .catch((e) => {
          console.log('error on logout .. ', e);
        });
    }
  }, [shouldLogout]);

  return (
    <Container>
      <SettingsHeader>
        <ArrowLeftWrapper
          onClick={
            activeSettingPage === settingsPages.profile
              ? handleOpenEditProfile
              : activeSettingPage === settingsPages.notifications
              ? handleOpenNotificationsSettings
              : handleCloseProfile
          }
        >
          <ArrowLeft />
        </ArrowLeftWrapper>
        <SectionHeader>
          {activeSettingPage === settingsPages.profile
            ? 'Edit profile'
            : activeSettingPage === settingsPages.notifications
            ? 'Notifications'
            : 'Settings'}
        </SectionHeader>
      </SettingsHeader>

      <ProfileInfo>
        <Avatar
          name={user.firstName || user.id}
          size={144}
          image={user.avatarUrl}
          setDefaultAvatar
        />
        <UserInfo>
          <Username>{`${user.firstName} ${user.lastName}`}</Username>
          <UserNumber>{`+${user.id}`}</UserNumber>
          <EditIconWrapper onClick={handleOpenEditProfile}>
            <EditIcon />
          </EditIconWrapper>
        </UserInfo>
      </ProfileInfo>

      <CustomUl>
        {/*   <CustomLi
          hoverBackground='none'
          iconWidth='20px'
          textColor={colors.gray5}
          iconColor={colors.gray4}
          margin='0 0 24px'
          onClick={handleOpenEditProfile}
        >
          <DefaultAvatar /> Profile
        </CustomLi>*/}
        {/*   <CustomLi
          onClick={handleOpenNotificationsSettings}
          hoverBackground='none'
          iconWidth='20px'
          textColor={colors.gray5}
          iconColor={colors.gray4}
          margin='0 0 24px'
        >
          <NotificationsIcon /> Notifications
        </CustomLi>*/}
        <CustomLi
          onClick={() => setAboutPopupIsOpen(true)}
          hoverBackground='none'
          iconWidth='20px'
          textColor={colors.textColor1}
          iconColor={colors.gray4}
          margin='0 0 24px'
        >
          <InfoIcon /> About WAAFI
        </CustomLi>
        <CustomLi
          onClick={handleLogout}
          hoverBackground='none'
          iconWidth='20px'
          textColor={colors.red1}
          iconColor={colors.red1}
          margin='0 0 24px'
        >
          <LogoutIcon /> Log Out
        </CustomLi>
      </CustomUl>

      {editProfileIsOpen && (
        <EditProfile
          user={user}
          updateUser={updateUser}
          handleCloseEditProfile={handleOpenEditProfile}
        />
      )}
      {/*{notificationsSettingsIsOpen && <NotificationsSettings user={user}  handleCloseNotificationsSettings={handleOpenNotificationsSettings} />}*/}
      {aboutPopupIsOpen && (
        <AboutPopup togglePopup={() => setAboutPopupIsOpen(false)} />
      )}
    </Container>
  );
};

export default ProfileSettings;

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 360px;
  z-index: 12;
  background-color: ${colors.white};
  border-right: 1px solid ${colors.gray1};
`;

const SettingsHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  height: 64px;
  border-bottom: 1px solid ${colors.gray1};
  box-sizing: border-box;
`;

const ArrowLeftWrapper = styled.span`
  display: flex;
  cursor: pointer;
  margin-right: 12px;
`;

const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0 24px;
`;

const Username = styled.h3`
  margin: 16px 0 0;
  text-align: center;
  font-weight: 500;
  font-size: 15px;
  word-wrap: break-word;
  white-space: pre-wrap;
  line-height: 18px;
  letter-spacing: -0.2px;
  max-width: 100%;
  color: ${colors.textColor1};
`;

const UserNumber = styled.h4`
  margin: 0;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.078px;
  color: ${colors.gray7};
`;

const SectionHeader = styled.h4<{ margin?: string }>`
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.textColor1};
  margin: ${(props) => props.margin || 0};
`;

const UserInfo = styled.div`
  position: relative;
  max-width: calc(100% - 80px);
`;

const EditIconWrapper = styled.span`
  position: absolute;
  right: -31px;
  top: 23px;
  cursor: pointer;
  color: ${colors.gray4};
`;
