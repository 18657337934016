import React, { useEffect, useState } from 'react'
import {
  Popup,
  PopupContainer,
  PopupDescription,
  PopupName,
  CloseIcon,
  PopupBody,
  Button,
  PopupFooter
} from '../../UIHelper'
import { colors } from '../../UIHelper/constants'

interface IProps {
  title: string
  description: string
  buttonText: string
  togglePopup: () => void
  deleteFunction: (option?: any) => void
  loading?: boolean
  isDeleteMessage?: boolean
}

function DeletePopup({
  title,
  description,
  buttonText,
  togglePopup,
  deleteFunction,
}: IProps) {
  const [initialRender, setInitialRender] = useState(true)

  const handleDelete = () => {
    deleteFunction()
    togglePopup()
  }

  useEffect(() => {
    setInitialRender(false)
  }, [])

  // @ts-ignore
  return (
    <PopupContainer>
      <Popup maxWidth='460px' minWidth='460px' padding='0'>
        <PopupBody padding={24}>
          <CloseIcon onClick={() => togglePopup()} />
          <PopupName isDelete marginBottom='20px'>
            {title}
          </PopupName>
          <PopupDescription>{description}</PopupDescription>
        </PopupBody>
        <PopupFooter backgroundColor={colors.gray0}>
          <Button type='button' color={colors.textColor1} backgroundColor='transparent' onClick={() => togglePopup()}>
            Cancel
          </Button>
          <Button
            type='button'
            backgroundColor='#FA4C56'
            borderRadius='8px'
            onClick={handleDelete}
            disabled={initialRender}
          >
            {buttonText || 'Delete'}
          </Button>
        </PopupFooter>
      </Popup>
    </PopupContainer>
  )
}

export default DeletePopup
