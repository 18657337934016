import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as EndCallIcon } from '../../../../../assets/svg/end-call.svg';
import { ReactComponent as VideoOffIcon } from '../../../../../assets/svg/video-off.svg';
import { ReactComponent as MuteIcon } from '../../../../../assets/svg/mute.svg';
import { ReactComponent as InviteUserIcon } from '../../../../../assets/svg/usersIcon.svg';
import { ReactComponent as ChatIcon } from '../../../../../assets/svg/message.svg';
import { ReactComponent as ShareScreenIcon } from '../../../../../assets/svg/share-screen.svg';
import { ReactComponent as FullScreenIcon } from '../../../../../assets/svg/fullscreen.svg';
import { ReactComponent as CloseFullScreenIcon } from '../../../../../assets/svg/coseFullScreen.svg';
import { ReactComponent as MinimizeIcon } from '../../../../../assets/svg/minimize-call.svg';
// @ts-ignore
import { ReactComponent as SettingsIcon } from '../../../../../assets/svg/settingsIcon.svg';
import {
  ButtonBlock,
  CallPopupWrapper,
  DefaultButton,
  PopupContainer,
} from '../../../../../UIHelper';
import { colors } from '../../../../../UIHelper/constants';
import styled from 'styled-components';
import ParticipantCallItem from './participantCallItem';
import { useDidUpdate } from '../../../../../hooks';
import UsersPopup from '../UsersPopup';
import SettingsPopup from './SettingsPopup';
import { Avatar } from 'sceyt-chat-react-uikit';
import MinimizedPopup from './MinimizedPopup';

import { ReactComponent as VoiceCallIcon } from '../../../../../assets/svg/phone.svg';
import { ReactComponent as VideoCallIcon } from '../../../../../assets/svg/video-call.svg';

const CallPopup = ({
  call,
  callName,
  callUsers,
  sceytClient,
  leaveCall,
  setIsRinging,
  screenSharing,
  hideActiveCall,
  setHideActiveCall,
  isDeclined,
  declineMessage,
  handleStartCall,
  isVideoCall,
  setCallTimeHandler,
}: {
  call: any;
  leaveCall: () => void;
  setIsRinging: (ringing: boolean) => void;
  callName: string;
  callUsers: any[];
  sceytClient: any;
  screenSharing: boolean;
  hideActiveCall: boolean;
  setHideActiveCall: (call: any) => void;
  isDeclined?: boolean;
  declineMessage?: string;
  handleStartCall: (video?: boolean) => void;
  isVideoCall: boolean;
  setCallTimeHandler: (callTime: any) => void;
}) => {
  const [userItemWidth, setUserItemWidth] = useState<any>(['200px']);
  const [callTime, setCallTime] = useState<any>({ min: 0, sec: 0 });
  const [callState, setCallState] = useState<any>('Calling...');
  const [otherUsersOnShareScreen, setOtherUsersOnShareScreen] =
    useState<any>(null);
  const [screenShareUser, setScreenShareUser] = useState<any>(null);
  const [fullScreen, setFullScreen] = useState(false);
  const [addAudioTracks, setAddAudioTracks] = useState<any>(false);
  const [inviteUserPopupOpen, setInviteUserPopupOpen] = useState<any>(false);
  const [inviteUserButtonIsActive, setInviteUserButtonIsActive] =
    useState<any>(true);
  const [mediaSettingsIsOpen, setMediaSettingsIsOpen] = useState<any>(false);
  const [minimizePopup, setMinimizePopup] = useState<any>(false);
  const [hidePopup, setHidePopup] = useState<any>(false);
  const [animateParticipantSize, setAnimateParticipantSize] =
    useState<any>(false);
  const [isP2PAudio, setIsP2PAudio] = useState<any>(
    call.mediaFlow === 'p2p' && !call.videoEnabled
  );
  const callCurrentTime = useRef<any>(callTime);
  const callTimerRef = useRef<any>(false);
  const [callNameState, setCallNameState] = useState<string>(callName);

  const handleLeaveCall = async () => {
    leaveCall();
  };

  const handleMuteCall = () => {
    call.mute(!call.muted);
  };
  const handleVideoEnable = () => {
    if (!screenSharing) {
      call.enableVideo(!call.localParticipant.videoEnabled);
    }
  };

  const handleShareScreen = async () => {
    try {
      // const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true });
      call.shareScreen(!screenSharing);
      // Now you have the screen stream
    } catch (error) {
      console.error('Error: Could not get screen stream.', error);
    }
  };

  // @ts-ignore
  const handleOpenMediaSettings = () => {
    setMediaSettingsIsOpen(true);
  };

  const handleStartCallTimer = () => {
    callTimerRef.current = setInterval(() => {
      setCallTime({
        min:
          callCurrentTime.current.sec >= 59
            ? callCurrentTime.current.min + 1
            : callCurrentTime.current.min,
        sec:
          callCurrentTime.current.sec < 59
            ? callCurrentTime.current.sec + 1
            : 0,
      });
    }, 1000);
  };

  const handleOpenInviteUserPopup = () => {
    if (!inviteUserPopupOpen && inviteUserButtonIsActive) {
      setInviteUserPopupOpen(!inviteUserPopupOpen);
    }
  };

  const handleOpenChat = () => {
    setHideActiveCall({ ...call, callName });
  };

  const handleOpeCloseFullScreen = () => {
    setFullScreen(!fullScreen);
  };

  const handleMinimizeScreen = () => {
    setMinimizePopup(!minimizePopup);
  };

  const handleInviteUser = async (selectedUsers: any) => {
    if (call.mediaFlow === 'p2p') {
      await call.switchToSFU();
    }
    await call.addParticipants(selectedUsers.map((user: any) => user.id));
  };

  const handleChangeMedia = async (selectedMedia: any) => {
    console.log('selectedMedia: ', selectedMedia);
  };

  useEffect(() => {
    const newCallPlaceHolder =
      callUsers.length &&
      callUsers.find((user: any) => user.id !== sceytClient.user.id);
    setCallNameState(
      (callUsers.length &&
        newCallPlaceHolder?.id &&
        `${newCallPlaceHolder.firstName} ${newCallPlaceHolder.lastName}`) ||
        callName
    );
  }, [callName, callUsers]);

  useEffect(() => {
    if (call.mediaFlow === 'sfu' && call.participants.length < 9) {
      setInviteUserButtonIsActive(true);
    }
    setTimeout(() => {
      setAnimateParticipantSize(true);
    }, 250);
    return () => {
      clearInterval(callTimerRef.current);
      callTimerRef.current = false;
    };
  }, []);

  useEffect(() => {
    callCurrentTime.current = callTime;
    setCallTimeHandler(callTime);
  }, [callTime]);

  useEffect(() => {
    if (hideActiveCall) {
      setTimeout(() => {
        setHidePopup(true);
      }, 400);
    } else {
      setHidePopup(false);
    }
  }, [hideActiveCall]);

  useEffect(() => {
    if (screenSharing) {
      setScreenShareUser(callUsers.find((user: any) => user.screenSharing));
      setOtherUsersOnShareScreen(
        callUsers.filter((user) => !user.screenSharing)
      );
    } else {
      setScreenShareUser(null);
      setOtherUsersOnShareScreen(null);
    }
  }, [screenSharing, callUsers]);

  useEffect(() => {
    if (minimizePopup) {
      if (callUsers.length <= 2) {
        setUserItemWidth(['100%']);
      } else if (callUsers.length <= 3) {
        setUserItemWidth(['100%', 'calc(50% - 8px)']);
      } else {
        setUserItemWidth(['calc(50% - 8px)']);
      }
    } else {
      if (callUsers.length <= 4) {
        setUserItemWidth(['calc(50% - 16px)']);
      } else if (callUsers.length <= 6) {
        setUserItemWidth(['calc(33% - 16px)']);
      } else if (callUsers.length <= 8) {
        setUserItemWidth(['calc(25% - 16px)']);
      } else {
        setUserItemWidth(['calc(25% - 16px)', 'calc(20% - 16px)']);
      }
    }
    if (inviteUserButtonIsActive && callUsers.length > 8) {
      setInviteUserButtonIsActive(false);
    }
  }, [callUsers.length, minimizePopup]);

  useDidUpdate(() => {
    if (!callTimerRef.current && callUsers.length > 0) {
      callUsers.forEach((user: any) => {
        if (user.id !== sceytClient.user.id) {
          if (user.state === 'connected') {
            handleStartCallTimer();
            setAddAudioTracks(true);
            setIsRinging(false);
            return;
          } else if (user.state === 'ringing') {
            setCallState('ringing...');
          }
        }
      });
    }
    setIsP2PAudio(call.mediaFlow === 'p2p' && !call.videoEnabled);
  }, [callUsers]);

  // remoteVideo = document.getElementById("remoteVideo");
  return (
    <PopupContainer
      width={minimizePopup || hidePopup ? '0' : '100%'}
      height={minimizePopup || hidePopup ? '0' : '100%'}
    >
      {minimizePopup ? (
        <MinimizedPopup
          call={call}
          addAudioTracks={addAudioTracks}
          sceytClient={sceytClient}
          callUsers={callUsers}
          userItemWidth={userItemWidth}
          leaveCall={handleLeaveCall}
          handleMinimizeScreen={handleMinimizeScreen}
        />
      ) : (
        <CallPopupWrapper
          borderRadius={fullScreen ? '0' : '16px'}
          justifyContent='space-between'
          backgroundColor={colors.textColor1}
          padding={'0'}
          maxWidth='inherit'
          width={hideActiveCall ? '0px' : fullScreen ? '100%' : '1000px'}
          minWidth={hideActiveCall ? '0px' : fullScreen ? '100vw' : '1000px'}
          height={hideActiveCall ? '0px' : fullScreen ? '100%' : '720px'}
          maxHeight='100%'
        >
          <CallHeader>
            <CallInfo>
              <CallName>{callNameState}</CallName>
              <CallState>
                {isDeclined
                  ? declineMessage
                  : callTimerRef.current
                  ? `${callTime.min <= 9 ? `0${callTime.min}` : callTime.min}:${
                      callTime.sec <= 9 ? `0${callTime.sec}` : callTime.sec
                    }`
                  : callState}
              </CallState>
            </CallInfo>
            <PopupMenageCont>
              {!fullScreen && (
                <IconWrapper
                  padding='4px'
                  active
                  onClick={handleMinimizeScreen}
                >
                  <MinimizeIcon />
                </IconWrapper>
              )}
              <IconWrapper
                padding='4px'
                active
                onClick={handleOpeCloseFullScreen}
              >
                {fullScreen ? <CloseFullScreenIcon /> : <FullScreenIcon />}
              </IconWrapper>
            </PopupMenageCont>
          </CallHeader>
          <CallBody>
            {screenShareUser ? (
              <SareScreenComponent>
                <SharingScreenVideo>
                  <ParticipantCallItem
                    call={call}
                    addAudioTracks={addAudioTracks}
                    sceytClient={sceytClient}
                    user={screenShareUser}
                    margin='0'
                    width={'100%'}
                    height='100%'
                  />
                </SharingScreenVideo>
                <OtherParticipants>
                  {otherUsersOnShareScreen
                    .slice(0, otherUsersOnShareScreen.length <= 3 ? 3 : 2)
                    .map((user: any, index: any) => (
                      <ParticipantCallItem
                        call={call}
                        addAudioTracks={addAudioTracks}
                        sceytClient={sceytClient}
                        user={user}
                        margin='0'
                        width={'100%'}
                        height={'100%'}
                        key={user.id + index}
                      />
                    ))}
                  {otherUsersOnShareScreen?.length > 3 && (
                    <MoreParticipantsItem
                      backgroundColor={'#2A2C2F'}
                      borderRadius='8px'
                      height={'100%'}
                      margin='0'
                    >
                      <MoreParticipants>
                        {otherUsersOnShareScreen
                          .slice(2, 5)
                          .map((user: any) => (
                            <OtherUsersAvatar key={user.id}>
                              <Avatar
                                image={user.avatarUrl}
                                size={40}
                                name={
                                  user.firstName
                                    ? `${user.firstName} ${user.lastName}`
                                    : user.id
                                }
                              />
                            </OtherUsersAvatar>
                          ))}
                      </MoreParticipants>
                      <MoreParticipantsText>
                        {' '}
                        +{otherUsersOnShareScreen.length - 2} more
                      </MoreParticipantsText>
                    </MoreParticipantsItem>
                  )}
                </OtherParticipants>
              </SareScreenComponent>
            ) : isP2PAudio ? (
              <ParticipantCallItem
                call={call}
                addAudioTracks={addAudioTracks}
                sceytClient={sceytClient}
                user={
                  callUsers.find(
                    (user: any) => user.id !== sceytClient.user.id
                  ) || {}
                }
                width={'100%'}
                isP2PAudioCall
                height={fullScreen ? '428px' : '236px'}
                animateSize={animateParticipantSize}
              />
            ) : (
              callUsers.map((user: any, index) => (
                <ParticipantCallItem
                  call={call}
                  addAudioTracks={addAudioTracks}
                  sceytClient={sceytClient}
                  user={user}
                  width={
                    callUsers.length > 8
                      ? index <= 3
                        ? userItemWidth[0]
                        : userItemWidth[1]
                      : userItemWidth[0]
                  }
                  key={user.id + index}
                  height={fullScreen ? '428px' : '236px'}
                  animateSize={animateParticipantSize}
                />
              ))
            )}
          </CallBody>
          <CallFooter>
            {!isDeclined ? (
              <ButtonBlock marginTop='0' justify={'center'}>
                <CallButtonWrapper
                  iconBackground={
                    call.muted ? '#fff' : 'rgba(98, 99, 101, 0.50)'
                  }
                  margin='0 12px'
                  onClick={handleMuteCall}
                >
                  <MuteIcon />
                </CallButtonWrapper>
                <CallButtonWrapper
                  iconBackground={
                    call.localParticipant.videoEnabled
                      ? 'rgba(98, 99, 101, 0.50)'
                      : '#fff'
                  }
                  margin='0 12px'
                  disabled={screenSharing}
                  onClick={handleVideoEnable}
                >
                  <VideoOffIcon />
                </CallButtonWrapper>
                <CallButtonWrapper
                  iconBackground={
                    screenSharing ? '#fff' : 'rgba(98, 99, 101, 0.50)'
                  }
                  margin='0 12px'
                  onClick={handleShareScreen}
                >
                  <ShareScreenIcon />
                </CallButtonWrapper>
              </ButtonBlock>
            ) : null}
            <CallButtonWrapper
              margin='0 0 0 12px'
              iconBackground={'rgba(250, 76, 86, 1)'}
              onClick={handleLeaveCall}
            >
              <EndCallIcon />
            </CallButtonWrapper>

            {isDeclined ? (
              <div
                onClick={() => handleStartCall(isVideoCall)}
                style={{
                  padding: '12px',
                  color: '#fff',
                  background: '#4CAF50',
                  margin: '0 12px',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                }}
              >
                {isVideoCall ? <VideoCallIcon /> : <VoiceCallIcon />}
              </div>
            ) : null}

            {!isDeclined ? (
              <AddUserBlock>
                {/*<DropDown
                                    height='24px'
                                    position='top'
                                    trigger={
                                        <IconWrapper active={!mediaSettingsIsOpen}>
                                            <SettingsIcon/>
                                        </IconWrapper>
                                    }
                                >
                                    <MediaSettingsPopup closePopup={() => setMediaSettingsIsOpen(false)}/>
                                </DropDown>*/}

                {/*<IconWrapper active={!mediaSettingsIsOpen} onClick={() => setMediaSettingsIsOpen(true)}>
                                    <SettingsIcon/>
                                </IconWrapper>*/}

                <IconWrapper active onClick={handleOpenChat}>
                  <ChatIcon />
                </IconWrapper>
                <IconWrapper
                  active={inviteUserButtonIsActive}
                  onClick={handleOpenInviteUserPopup}
                >
                  <InviteUserIcon />
                </IconWrapper>
              </AddUserBlock>
            ) : null}
          </CallFooter>
        </CallPopupWrapper>
      )}

      {mediaSettingsIsOpen && (
        <SettingsPopup
          submitHandler={handleChangeMedia}
          togglePopup={() => setMediaSettingsIsOpen(false)}
          sceytClient={sceytClient}
          popupHeight='540px'
          popupWidth='520px'
          popupTitleText='Settings'
          currentAudioTrack={call.localParticipant.audioTracks[0]}
          currentVideoTrack={call.localParticipant.videoTracks[0]}
        />
      )}
      {inviteUserPopupOpen && (
        <UsersPopup
          memberIds={call.participants.map(
            (participant: any) => participant.id
          )}
          submitHandler={handleInviteUser}
          sceytClient={sceytClient}
          toggleCreatePopup={() => setInviteUserPopupOpen(false)}
          maxSelectionCount={
            call.participants.length < 9 ? 9 - call.participants.length : 0
          }
          actionType='inviteUser'
          popupHeight='540px'
          popupWidth='520px'
          popupTitleText={'Add to call'}
        />
      )}
    </PopupContainer>
  );
};

export default CallPopup;

const CallButtonWrapper = styled(DefaultButton)<{
  margin?: string;
  iconBackground?: string;
  hoverIconBackground?: string;
  disabled?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${(props) => props.margin};
  pointer-events: ${(props) => props.disabled && 'none'};

  & svg {
    color: ${(props) => props.iconBackground};
  }

  &:hover svg {
    color: ${(props) => props.hoverIconBackground};
  }
`;

const CallHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  min-width: 400px;
`;
const CallInfo = styled.div``;
const PopupMenageCont = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
`;
const CallName = styled.h3`
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: ${colors.white};
  margin: 0;
`;

const CallState = styled.p`
  color: #b9babb;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.078px;
  margin: 0;
  height: 20px;
`;

const CallBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: calc(100% - 200px);
  padding: 0 24px;
`;

const CallFooter = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 16px 0;
  background: rgba(46, 48, 51, 0.8);
  backdrop-filter: blur(12px);
  border-radius: 0 0 16px 16px;
`;

const AddUserBlock = styled.div`
  position: absolute;
  right: 40px;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const SareScreenComponent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;
const SharingScreenVideo = styled.div`
  width: 75%;
  height: 100%;
  margin-right: 16px;
`;

const OtherParticipants = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(25% - 16px);
  gap: 16px;
`;

const MoreParticipantsItem = styled.div<{
  width?: string;
  height?: string;
  backgroundColor?: string;
  borderRadius?: string;
  margin?: string;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  border-radius: ${(props) => props.borderRadius};
  margin: ${(props) => props.margin || '4px'};
  width: ${(props) => props.width || '100%'};
  height: ${(props) => props.height};
  color: #fff;
`;

const MoreParticipants = styled.div`
  display: flex;
`;

const MoreParticipantsText = styled.div`
  margin-top: 16px;
`;

const OtherUsersAvatar = styled.div`
  width: 40px;
  height: 40px;
  margin: 0 -6px;
  border-radius: 50%;
  border: 2px solid ${colors.textColor1};

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
const IconWrapper = styled.span<{
  margin?: string;
  active?: boolean;
  padding?: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  cursor: ${(props) => (props.active ? 'pointer' : 'default')};
  opacity: ${(props) => (props.active ? 1 : 0.5)};
`;
