// Need to use the React-specific entry point to import createApi
import { createApi } from '@reduxjs/toolkit/query/react';
import baseQuery from '../baseQuery';
import { OGMetadataResponse } from '../../types/og';

export const ogServiceApi = createApi({
  reducerPath: 'ogServiceApi',
  baseQuery: baseQuery,
  tagTypes: ['GET_POST', 'GET_USER_POSTS'],
  endpoints: (builder) => ({
    getOGMetadata: builder.query<OGMetadataResponse, any>({
      query: (url) => ({
        url: '/metadata?url=' + url,
        method: 'GET'
      }),
      providesTags: ['GET_POST']
    })
  })
});
