import React, { useEffect, useState } from 'react';
import { IAttachment } from 'sceyt-chat-react-uikit/types';
import style from './style.module.css';

const OGMetadata = ({
  attachments,
  state,
}: {
  attachments: IAttachment[];
  state: string;
}) => {
  const [metadata, setMetadata] = useState<any | null>(null);

  useEffect(() => {
    if (
      attachments.length > 0 &&
      attachments.find((attachment) => attachment.type === 'link')?.id
    ) {
      const firstAttachment = attachments.find(
        (attachment) => attachment.type === 'link'
      );
      const fetchMetadata = async () => {
        try {
          const response = await fetch(
            `/api/metadata?url=${firstAttachment?.url}&anonymous=1`
          );
          if (!response.ok) {
            throw new Error('Failed to fetch metadata');
          }
          const data = await response.json();
          setMetadata(data);
        } catch (error) {
          console.error('Error fetching metadata:', error);
        }
      };

      fetchMetadata();
    }
  }, [attachments]);

  return state === 'deleted' ||
    metadata?.title === '' ||
    metadata?.image === '' ||
    metadata?.description === '' ? null : (
    <>
      {metadata ? (
        <div
          onClick={() => {
            window.open(
              attachments.find((attachment) => attachment.type === 'link')?.url,
              '_blank'
            );
          }}
          className={style.container}
        >
          {metadata?.image ? (
            <div className={style.imgContainer}>
              <img src={metadata?.image} alt='' />
            </div>
          ) : null}
          <div className={style.ogText}>
            <p className={style.url}>
              {
                new URL(
                  attachments.find(
                    (attachment) => attachment.type === 'link'
                  )?.url
                ).hostname
              }
            </p>
            {metadata?.title ? (
              <p className={style.title}>{metadata?.title}</p>
            ) : null}
            {metadata?.description ? (
              <p className={style.desc}>{metadata?.description}</p>
            ) : null}
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default OGMetadata;
