import styled from 'styled-components';
import React, { useEffect, useRef, useState } from 'react';
import { Avatar } from 'sceyt-chat-react-uikit';
import { ReactComponent as CameraIcon } from '../../../../../../assets/svg/camera.svg';
import { ReactComponent as PictureIcon } from '../../../../../../assets/svg/picture.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/svg/trash.svg';
import { colors } from '../../../../../../UIHelper/constants';
import {
  Button,
  CustomInput,
  CustomLi,
  CustomUl,
  InputErrorMessage,
  InputGroup,
  Label,
  PopupFooter,
} from '../../../../../../UIHelper';
import useStateComplex from '../../../../../../hooks/basic/useStateComplex';
import DropDown from '../../../../../../common/dropdown';
import ImageCrop from '../../../../../../common/imageCrop';
import DeletePopup from '../../../../../../popups/delete';
import { getSceytClient } from '../../../../../../helpers/client';
import { resizeImage } from '../../../../../../helpers/resizeImage';

interface IProps {
  user: any;
  handleCloseEditProfile: () => void;
  updateUser: (updatedUser: any) => void;
}

const EditProfile = ({ handleCloseEditProfile, user, updateUser }: IProps) => {
  const client = getSceytClient();
  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [presenceStatus, setPresenceStatus] = useState(user.presence?.status);
  const [presenceStateError, setPresenceStateError] = useState('');
  const [presenceStatusLength, setPresenceStatusLength] = useState(
    user.presence?.status.length
  );
  const [cropPopup, setCropPopup] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState('');
  const [deleteAvatarPopupOpen, setDeleteAvatarPopupOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [newAvatar, setNewAvatar] = useStateComplex({
    src: {},
    url: user.avatarUrl,
  });

  const fileUploader = useRef<any>(null);
  /*  const handleEditProfile = () => {

  } */

  const onOpenFileUploader = () => {
    fileUploader.current.click();
  };
  function handleSelectImage(image: File) {
    setNewAvatar({
      name: image.name,
    });
    setSelectedImageUrl(URL.createObjectURL(image));
    setCropPopup(true);
  }

  const handleImageCrop = async (image: File) => {
    const blob = await resizeImage(image, undefined, undefined, 0.9);
    const file = new File([blob], image.name);
    setNewAvatar({
      src: {
        file,
      },
      url: URL.createObjectURL(file),
    });
    // handleUpdateChannel({ avatar: image })
  };

  const handleRemoveAvatar = () => {
    setNewAvatar({
      src: {},
      url: '',
    });
    // handleUpdateChannel({ avatarUrl: '' })
  };

  const handleFileUpload = () => {
    const file = fileUploader.current.files[0];
    handleSelectImage(file);
  };

  const handleToggleDeleteAvatarPopup = () => {
    setDeleteAvatarPopupOpen(!deleteAvatarPopupOpen);
  };

  const handleTypeFirstName = (e: any) => {
    setFirstName(e.target.value);
  };

  const handleTypeLastName = (e: any) => {
    setLastName(e.target.value);
  };

  const handleTypePresenceStatus = (e: any) => {
    setPresenceStatusLength(e.target.value.length);
    setPresenceStatus(e.target.value);
    if (e.target.value.length > 140) {
      setPresenceStateError('Too large text');
    } else if (presenceStateError) {
      setPresenceStateError('');
    }
  };
  const updateUserProfile = (
    userUpdatedParams: any,
    presenceStatus: string
  ) => {
    client
      .setProfile(userUpdatedParams)
      .then(async (updatedUser: any) => {
        if (presenceStatus !== user.presence.status) {
          await client.setPresence('online', presenceStatus);
          updateUser({
            ...user,
            ...updatedUser,
            presence: { ...user.presence, status: presenceStatus },
          });
        } else {
          updateUser({ ...user, ...updatedUser });
        }
      })
      .catch((e: any) => {
        console.log('error update profile. .. ', e);
      });
  };

  const handleEditProfile = async () => {
    // dispatch(updateProfileAC(user, firstName, lastName, '', '', undefined))
    const updateUserParams: any = {};
    if (firstName !== user.firstName) {
      updateUserParams.firstName = firstName;
    }
    if (lastName !== user.lastName) {
      updateUserParams.lastName = lastName;
    }
    if (newAvatar.url && newAvatar.url !== user.avatarUrl) {
      /*   const uploader = new AWSUploader();
         setUploading(true)
         const uploadTask: any = {
           failure: (e: Error) => console.log('error on upload avatar: ', e),
           success: (uri: string) => {
             uploader.download(uri).then((url) => {
               updateUserParams.avatarUrl = url
               updateUserProfile(updateUserParams)
               setUploading(false)
               handleCloseEditProfile()
             })
           },
           cancel: () => {},
           stop: () => {},
           resume: () => {}
         }
         uploader.upload(newAvatar.src.file, uploadTask)*/

      setUploading(true);
      const fileToUpload = {
        data: newAvatar.src.file,
        progress: (progressPercent: number) => {
          console.log('upload percent - ', progressPercent);
        },
      };
      client
        .uploadFile(fileToUpload)
        .then((url: any) => {
          updateUserParams.avatarUrl = url;
          updateUserProfile(updateUserParams, presenceStatus);
          setUploading(false);
          handleCloseEditProfile();
        })
        .catch((error: any) => {
          console.log('error on upload avatar: ', error);
        });
    } else {
      if (user.avatarUrl && !newAvatar.url) {
        updateUserParams.avatarUrl = newAvatar.url;
      }
      updateUserProfile(updateUserParams, presenceStatus);

      handleCloseEditProfile();
    }
  };

  useEffect(() => {
    return () => {
      setSelectedImageUrl('');
      setNewAvatar({
        src: {},
        url: user.avatarUrl, // channelDetails.avatar
      });
    };
  }, []);

  return (
    <Container>
      <EditAvatarCont>
        <Avatar
          name={user.firstName || user.id}
          size={144}
          image={newAvatar.url}
          setDefaultAvatar
        />
        <EditAvatarLayer>
          <DropDown
            iconColor={'#fff'}
            position='center'
            trigger={<CameraIcon />}
            width='100%'
          >
            <CustomUl>
              <CustomLi
                key={1}
                onClick={() => onOpenFileUploader()}
                iconWidth='20px'
              >
                <PictureIcon />
                <UploadFileLabel>Upload Avatar</UploadFileLabel>
                <UploadFile
                  ref={fileUploader}
                  accept='.png,.jpeg,.jpg'
                  onChange={handleFileUpload}
                  type='file'
                />
              </CustomLi>
              {newAvatar.url && (
                <CustomLi
                  key={2}
                  textColor={colors.red1}
                  onClick={handleToggleDeleteAvatarPopup}
                  iconWidth='20px'
                >
                  <DeleteIcon />
                  Remove Avatar
                </CustomLi>
              )}
            </CustomUl>
          </DropDown>
        </EditAvatarLayer>
      </EditAvatarCont>

      <EditProfileBody>
        <Label color={colors.textColor1}>Firstname</Label>
        <CustomInput
          type='text'
          value={firstName}
          onChange={handleTypeFirstName}
          placeholder='Firstname'
        />

        <Label color={colors.textColor1}>Lastname</Label>
        <CustomInput
          type='text'
          value={lastName}
          onChange={handleTypeLastName}
          placeholder='Lastname'
        />

        <InputGroup>
          <Label color={colors.textColor1}>About</Label>
          <ValidationTooltip>{presenceStatusLength}/140</ValidationTooltip>
          <CustomInput
            type='text'
            value={presenceStatus}
            error={!!presenceStateError}
            onChange={handleTypePresenceStatus}
            placeholder='About'
          />
          {!!presenceStateError && (
            <InputErrorMessage>{presenceStateError}</InputErrorMessage>
          )}
        </InputGroup>
      </EditProfileBody>

      <PopupFooter>
        <Button
          onClick={handleCloseEditProfile}
          backgroundColor={colors.gray0}
          color={colors.textColor1}
          borderRadius='8px'
        >
          Cancel
        </Button>
        <Button
          disabled={!!presenceStateError || uploading}
          onClick={handleEditProfile}
          backgroundColor={colors.green1}
          borderRadius='8px'
          margin='0 0 0 12px'
        >
          Save
        </Button>
      </PopupFooter>

      {cropPopup && (
        <ImageCrop
          image={{ name: newAvatar.name, url: selectedImageUrl }}
          onAccept={handleImageCrop}
          handleClosePopup={() => setCropPopup(false)}
        />
      )}

      {deleteAvatarPopupOpen && (
        <DeletePopup
          deleteFunction={handleRemoveAvatar}
          togglePopup={handleToggleDeleteAvatarPopup}
          title='Remove avatar?'
          description='Are you sure you want to remove your avatar?'
          buttonText='Remove'
        />
      )}
    </Container>
  );
};

export default EditProfile;

const Container = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 64px;
  left: 0;
  background-color: ${colors.white};
`;

const EditAvatarCont = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 20px 0 24px;
`;

const EditAvatarLayer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 144px;
  height: 144px;
  background: linear-gradient(
    0deg,
    rgba(23, 25, 28, 0.4),
    rgba(23, 25, 28, 0.4)
  );
  border-radius: 50%;
  cursor: pointer;
`;

const EditProfileBody = styled.div`
  padding: 0 16px;
  margin-bottom: 16px;
`;

const ValidationTooltip = styled.span`
  position: absolute;
  right: 0;
  top: 21px;
  color: ${colors.gray7};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
`;

const UploadFileLabel = styled.label`
  cursor: pointer;
  width: 100%;
  display: block;
`;
const UploadFile = styled.input`
  display: none;
`;
