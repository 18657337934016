import React from 'react';
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import styles from './styles.module.css';
import { HiddenMessageTime, MessageStatusAndTime } from '..';
import { IMessage } from 'sceyt-chat-react-uikit/types';
import moment from 'moment';

export const SharedLocation = ({
  metadata,
  message,
}: {
  metadata: string;
  message: IMessage;
}) => {
  const [lat, lng] = metadata.split(';').map((coord) => parseFloat(coord));
  const position = { lat, lng };
  const handleMarkerClick = () => {
    const url = `https://www.google.com/maps?q=${position.lat},${position.lng}&ll=${position.lat},${position.lng}&z=16`;
    window.open(url, '_blank');
  };
  return (
    <div className={styles.location}>
      <APIProvider apiKey={'AIzaSyBhPCzt1dROhCyjo1TZv0Q0swL6PeKhaZ0'}>
        <Map
          defaultCenter={position}
          defaultZoom={16}
          fullscreenControl={false}
          controlSize={24}
          center={position}
          clickableIcons={true}
          zoomControl={true}
          tilt={0}
          minZoom={10}
          maxZoom={20}
        >
          <Marker position={position} onClick={handleMarkerClick} />
        </Map>
      </APIProvider>

      {message.incoming && (
        <MessageStatusAndTime
          withAttachment
          leftMargin
          isSelfMessage={!message.incoming}
          fileAttachment={
            message.attachments[0].type === 'file' ||
            message.attachments[0].type === 'voice'
          }
        >
          <HiddenMessageTime>{`${moment(message.createdAt).format(
            'HH:mm'
          )}`}</HiddenMessageTime>
        </MessageStatusAndTime>
      )}
    </div>
  );
};
