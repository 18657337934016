import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import QRCode from 'qrcode';
import { ReactComponent as WaafiIcon } from '../../../assets/svg/waafi.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/svg/QRArrow.svg';
import { rootApi } from '../../../api';

interface IProps {
  setChatToken: (tokenData: { token: string; subscriptionId: string }) => void;
}

function Init({ setChatToken }: IProps) {
  const canvasRef = useRef();
  const checkTokenInterval: any = useRef();
  const intervalRef: any = useRef();
  const [token, setToken] = useState('');
  const date = new Date();
  console.info(
    `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : initiate`
  );

  const handleUpdateToken = () => {
    rootApi
      .post('chat/initiate')
      .then((response) => {
        const {
          data: {
            payload: { token },
          },
        } = response;
        setToken(token);
        QRCode.toCanvas(
          canvasRef.current,
          token,
          // @ts-ignore
          { toSJISFunc: QRCode.toSJIS },
          function (error) {
            if (error) console.error(error);
          }
        );
      })
      .catch((e) => {
        console.error(
          `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : initiate error - `,
          e
        );
      });
  };

  useEffect(() => {
    // const chatToken = localStorage.getItem('chatToken')
    // if (!chatToken) {

    // }
    handleUpdateToken();
    intervalRef.current = setInterval(() => {
      console.log('update qr token.... ');
      handleUpdateToken();
    }, 50000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);
  useEffect(() => {
    if (token) {
      clearInterval(checkTokenInterval.current);
      checkTokenInterval.current = setInterval(() => {
        rootApi
          .post('chat/check/token', JSON.stringify({ payload: { token } }))
          .then((response) => {
            clearInterval(checkTokenInterval.current);
            localStorage.setItem(
              'refreshToken',
              JSON.stringify({
                token: response.data.payload.refreshToken,
                chatToken: response.data.payload.token,
                subscriptionId: response.data.payload.subscriptionId,
              })
            );

            localStorage.setItem(
              'clientOptions',
              JSON.stringify(response.data.payload?.clientOptions)
            );

            setChatToken({
              token: response.data.payload.token,
              subscriptionId: response.data.payload.subscriptionId,
            });
          })
          .catch(() => {
            const date = new Date();
            console.error(
              `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()} : Failed check token ... `
            );
          });
      }, 2000);
    }
    return () => {
      clearInterval(checkTokenInterval.current);
    };
  }, [token]);

  return (
    <InitContainer>
      <InitHeader>WAAFI</InitHeader>
      <InstructionsContainer>
        <Instructions>
          <InstructionsHeader>
            Use <WaafiIcon />
            <span>on your computer:</span>
          </InstructionsHeader>
          <InstructionsList>
            <InstructionsListItem>
              1. Open WAAFI app on your phone
            </InstructionsListItem>
            <InstructionsListItem>
              2. Open Settings and select Linked Devices
            </InstructionsListItem>
            <InstructionsListItem>
              3. Point your phone to this screen to capture the QR
            </InstructionsListItem>
          </InstructionsList>
        </Instructions>

        <Canvas ref={canvasRef} width='250' />

        <ArrowIconCont>
          {' '}
          <ArrowIcon />{' '}
        </ArrowIconCont>
      </InstructionsContainer>
    </InitContainer>
  );
}

export default Init;

const InitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(70.6deg, #dbfff9 27.49%, #ebebff 90.41%);
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const InitHeader = styled.div`
  width: calc(100% - 36px);
  background: #fff;
  text-align: center;
  padding: 18px;
  font-weight: 700;
  font-size: 28px;
  line-height: 36px;

  color: #17191c;
`;

const InstructionsContainer = styled.div`
  position: relative;
  display: flex;
  padding: 56px 72px;
  background: #fff;
  color: #17191c;
  border-radius: 20px;
  margin-top: 80px;
  box-shadow: 0 10px 50px rgba(23, 25, 28, 0.08);
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
    padding: 30px 40px;
  }
`;

const InstructionsHeader = styled.h3`
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  color: #17191c;
  margin: 0 0 18px;

  span {
    display: block;
  }
`;

const Instructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 80px;
  @media (max-width: 900px) {
    margin-right: 0;
  }
`;
const Canvas = styled.canvas<any>`
  width: 250px !important;
  height: 250px !important;
`;

const ArrowIconCont = styled.div`
  position: absolute;
  right: -63px;
  bottom: -35px;
`;

const InstructionsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`;

const InstructionsListItem = styled.li`
  font-size: 15px;
  line-height: 30px;
  letter-spacing: -0.2px;
  color: #58595c;
`;
