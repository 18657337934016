import React from 'react';
// @ts-ignore
import Favico from 'favico.js';
import styled from 'styled-components';
import { ReactComponent as ReactionIcon } from '../../../../assets/svg/emojiSmileIcon.svg';
import { ReactComponent as EditMessageIcon } from '../../../../assets/svg/edit.svg';
import { ReactComponent as CheckIcon } from '../../../../assets/svg/checkCircle.svg';
import { ReactComponent as ResendMessageIcon } from '../../../../assets/svg/resend.svg';
import { ReactComponent as ReplyMessageIcon } from '../../../../assets/svg/reply.svg';
import { ReactComponent as CopyMessageIcon } from '../../../../assets/svg/copy.svg';
import { ReactComponent as ForwardMessageIcon } from '../../../../assets/svg/forward.svg';
import { ReactComponent as DeleteMessageIcon } from '../../../../assets/svg/trash.svg';
import { colors } from '../../../../UIHelper/constants';
import { ItemNote } from '../../../../UIHelper';
import {
  CHANNEL_TYPE,
  MESSAGE_DELIVERY_STATUS,
  USER_STATE,
} from '../../../../helpers/constants';
import moment from 'moment';

function CustomMessageActionsMenu({
  message,
  channel,
  handleSetMessageForEdit,
  handleResendMessage,
  handleOpenDeleteMessage,
  handleOpenForwardMessage,
  handleCopyMessage,
  handleOpenEmojis,
  handleReplyMessage,
  handleSelectMessage,
  isThreadMessage,
  rtlDirection,
  rolesMap,
  client,
}: {
  message: any;
  channel: any;
  rolesMap: any;
  handleSetMessageForEdit?: () => void;
  handleResendMessage?: () => void;
  handleOpenDeleteMessage?: () => void;
  handleOpenForwardMessage?: () => void;
  handleCopyMessage?: () => void;
  handleReportMessage?: () => void;
  handleSelectMessage?: () => void;
  handleOpenEmojis?: () => void;
  handleReplyMessage?: (threadReply?: boolean) => void;

  isThreadMessage?: boolean;
  rtlDirection?: boolean;
  client: any;
}) {
  const isDirectChannel = channel.type === CHANNEL_TYPE.DIRECT;
  const directChannelUser =
    isDirectChannel &&
    channel.members.find((member: any) => member.id !== client.user.id);
  const isIncoming = message.incoming;
  const checkPermission = (permission: string) => {
    if (rolesMap && channel.userRole) {
      return rolesMap[channel.userRole].includes(permission);
    } else {
      return false;
    }
  };
  let messageCreatesHours = moment().diff(moment(message.createdAt), 'hours');

  const editMessagePermitted = isIncoming
    ? checkPermission('editAnyMessage')
    : checkPermission('editOwnMessage');

  return (
    <Container rtlDirection={rtlDirection} isThreadMessage={isThreadMessage}>
      <ActionsMenu rtlDirection={rtlDirection}>
        <Action onClick={handleOpenEmojis}>
          <ItemNote direction='top'>React</ItemNote>
          <ReactionIcon />
        </Action>

        {message.deliveryStatus !== MESSAGE_DELIVERY_STATUS.PENDING &&
          messageCreatesHours < 24 &&
          !isIncoming &&
          !(
            message.forwardingDetails &&
            message.forwardingDetails.user &&
            message.forwardingDetails.user.id !== client.user.id
          ) &&
          editMessagePermitted &&
          (isDirectChannel && directChannelUser
            ? !isIncoming && directChannelUser.state !== USER_STATE.DELETED
            : true) && (
            <Action onClick={handleSetMessageForEdit}>
              <ItemNote direction='top'>Edit Message</ItemNote>
              <EditMessageIcon />
            </Action>
          )}

        {message.state === 'failed' && (
          <Action onClick={handleResendMessage}>
            <ItemNote direction='top'>Resend Message</ItemNote>
            <ResendMessageIcon />
          </Action>
        )}

        <Action onClick={() => handleReplyMessage && handleReplyMessage()}>
          <ItemNote direction='top'>Reply</ItemNote>
          <ReplyMessageIcon />
        </Action>

        <Action onClick={handleCopyMessage}>
          <ItemNote direction='top'>Copy</ItemNote>
          <CopyMessageIcon />
        </Action>

        <Action onClick={handleOpenForwardMessage}>
          <ItemNote direction='top'>Forward Message</ItemNote>
          <ForwardMessageIcon />
        </Action>
        <Action onClick={handleSelectMessage}>
          <ItemNote direction='top'>Select</ItemNote>
          <CheckIcon />
        </Action>

        <Action onClick={handleOpenDeleteMessage}>
          <ItemNote direction='top'>Delete Message</ItemNote>
          <DeleteMessageIcon />
        </Action>
      </ActionsMenu>
    </Container>
  );
}

export default CustomMessageActionsMenu;

export const Container = styled.div<{
  rtlDirection?: boolean;
  isThreadMessage?: boolean;
}>`
  position: absolute;
  left: ${({ isThreadMessage, rtlDirection }) =>
    !rtlDirection && (isThreadMessage ? '8px' : '0')};
  right: ${({ rtlDirection }) => rtlDirection && '0'};
  direction: ${(props) => (props.rtlDirection ? 'initial' : '')};
  top: -46px;
  padding: 0 0 8px;
  z-index: 200;
`;

export const ActionsMenu = styled.div<{ rtlDirection?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  direction: ${(props) => props.rtlDirection && 'initial'};
  background-color: ${colors.white};
  padding: 8px 2px;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0 0 2px rgba(17, 21, 57, 0.08), 0 0 24px rgba(17, 21, 57, 0.16);
  transition: all 0.2s;
  z-index: 100;
`;
const Action = styled.div<{
    color?: string
    iconColor?: string
    order?: number
    hoverIconColor?: string
    hoverBackgroundColor?: string
}>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  margin: 0 6px;
  cursor: pointer;
  transition: all 0.2s;
  color: ${colors.textColor2};
  border-radius: 50%;

  &:hover {
    color: ${(props) => props.hoverIconColor || colors.primary};
    background-color: ${(props) => props.hoverBackgroundColor || '#f1f2f6'};

    ${ItemNote} {
      display: block;
    }
  }
`