import React, { useEffect, useState } from 'react';
import { Avatar } from 'sceyt-chat-react-uikit';

import styled, { keyframes } from 'styled-components';
import { colors } from '../../../../../../UIHelper/constants';
import { ReactComponent as MuteIcon } from '../../../../../../assets/svg/userMuted.svg';
import UserVideo from './UserVideo';

const ParticipantCallItem = ({
  sceytClient,
  addAudioTracks,
  width,
  height,
  margin,
  user,
  animateSize,
  isP2PAudioCall,
}: {
  sceytClient: any;
  call: any;
  margin?: string;
  width: string;
  height?: string;
  user: any;
  addAudioTracks: boolean;
  isP2PAudioCall?: boolean;
  animateSize?: boolean;
}) => {
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setShowVideo(user.videoEnabled);
  }, [user.videoEnabled]);

  return (
    <ParticipantItem
      width={width}
      height={height}
      margin={margin}
      animateSize={animateSize}
      backgroundColor={isP2PAudioCall ? 'inherit' : ''}
    >
      <UserVideo
        user={user}
        addAudioTracks={addAudioTracks}
        sceytClient={sceytClient}
      ></UserVideo>
      {!showVideo && (
        <Avatar
          image={user.avatarUrl}
          size={isP2PAudioCall ? 180 : 90}
          name={user.firstName ? `${user.firstName} ${user.lastName}` : user.id}
          textSize={isP2PAudioCall ? 46 : 14}
        />
      )}
      {!isP2PAudioCall && (
        <UserInfo>
          <UserName>
            {user.firstName ? `${user.firstName} ${user.lastName}` : user.id}
            {user.muted && <MuteIcon />}
          </UserName>
          {user.state !== 'connected' && (
            <TypingAnimation>
              <DotOne />
              <DotTwo />
              <DotThree />
            </TypingAnimation>
          )}
        </UserInfo>
      )}
    </ParticipantItem>
  );
};

export default React.memo(ParticipantCallItem, (prevProps, nextProps) => {
  return (
    prevProps.call.isVideoCall === nextProps.call.isVideoCall &&
    prevProps.addAudioTracks === nextProps.addAudioTracks &&
    prevProps.user.state === nextProps.user.state &&
    prevProps.user.muted === nextProps.user.muted &&
    prevProps.user.id === nextProps.user.id &&
    prevProps.user.videoEnabled === nextProps.user.videoEnabled &&
    prevProps.width === nextProps.width &&
    prevProps.height === nextProps.height &&
    prevProps.margin === nextProps.margin &&
    prevProps.animateSize === nextProps.animateSize &&
    prevProps.user.videoTracks === nextProps.user.videoTracks &&
    prevProps.user.audioTracks === nextProps.user.audioTracks &&
    prevProps.isP2PAudioCall === nextProps.isP2PAudioCall
  );
});

const ParticipantItem = styled.div<{
  width?: string;
  height?: string;
  margin?: string;
  animateSize?: boolean;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.width};
  height: ${(props) => props.height || '236px'};
  margin: ${(props) => props.margin || '8px'};
  background-color: ${(props) => props.backgroundColor || '#2A2C2F'};
  border-radius: 8px;
  overflow: hidden;
  color: white;
  transition: ${(props) => props.animateSize && 'all 0.4s'};

  & > video {
    max-width: 100%;
    max-height: 100%;
  }
`;

const UserInfo = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  background: rgba(23, 25, 28, 0.6);
  backdrop-filter: blur(8px);
  padding: 4px 8px;
  max-width: calc(100% - 36px);
`;

const UserName = styled.span`
    color: ${colors.white};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.2px;
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    gap: 4px;
}

`;

const sizeAnimation = keyframes`
    0% {
        width: 4px;
        height: 4px;
        opacity: 0.4;
    }
    50% {
        width: 6px;
        height: 6px;
        opacity: 1;
    }
    100% {
        width: 4px;
        height: 4px;
        opacity: 0.4;
    }
`;

const DotOne = styled.span``;
const DotTwo = styled.span``;
const DotThree = styled.span``;
const TypingAnimation = styled.div`
  display: flex;
  margin-left: 8px;

  & > span {
    position: relative;
    width: 6px;
    height: 6px;
    margin-right: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-timing-function: linear;

    &:after {
      content: '';
      position: absolute;

      width: 3.5px;
      height: 3.5px;
      border-radius: 50%;
      background-color: ${colors.white};
      animation-name: ${sizeAnimation};
      animation-duration: 0.8s;
      animation-iteration-count: infinite;
    }
  }

  & ${DotOne} {
    &:after {
      animation-delay: 0s;
    }
  }

  & ${DotTwo} {
    &:after {
      animation-delay: 0.2s;
    }
  }

  & ${DotThree} {
    &:after {
      animation-delay: 0.3s;
    }
  }
`;
