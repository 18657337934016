export const colors = {
  white: '#ffffff',

  textColor1: '#17191C',
  textColor2: '#707388',
  blue1: '#18273A',
  blue2: '#383B51',
  blue3: '#438CED',

  gray0: '#F0F2F5',
  gray1: '#EDEDED',
  gray2: '#ecedf0',
  gray3: '#D0D8E3',
  gray4: '#818C99',
  gray6: '#898B99',
  gray7: '#757D8B',

  green1: '#0DBD8B',

  red1: '#FA4C56',
  red2: '#d7596c',

  primary: '#0DBD8B',
  primaryLight: '#F3F5F7'
}

