import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
// @ts-ignore
import SceytCallClient from 'sceyt-call';

import { ReactComponent as VoiceCallIcon } from '../../../../assets/svg/phone.svg';
import { ReactComponent as VideoCallIcon } from '../../../../assets/svg/video-call.svg';
import { DefaultButton } from '../../../../UIHelper';
import useDidUpdate from '../../../../hooks/basic/useDidUpdate';
import IncomingCallPopup from './IncomingCallPopup';
import CallPopup from './CallPopup';
import UsersPopup from './UsersPopup';
import { CallSound, IMember, ParticipantEvent } from '../../../../types';
// @ts-ignore
import AudioSrc from '../../../../assets/call_ringing.mp3';
// @ts-ignore
import RingingAudio from '../../../../assets/phone-ringing.mp3';
// @ts-ignore
import DeclinedAudio from '../../../../assets/call_declined.mp3';
import { makeFirstById, safePlay } from '../../../../helpers';
import { getActiveChannel } from '../../../../helpers/activeChannel';
import axios from 'axios';
// import { sendMessage } from 'sceyt-chat-react-uikit';
import { getClientOptions, getItem } from '../../../../helpers/storage';

let sounds: Record<CallSound, HTMLAudioElement>;

let shouldPlay = false;
export function initializeSounds() {
  if (sounds) {
    return;
  }
  const incomingAudio = new Audio(RingingAudio);
  incomingAudio.loop = true;
  incomingAudio.onpause = () => {
    if (shouldPlay) {
      safePlay(incomingAudio);
    }
  };
  incomingAudio.onplay = () => {
    if (!shouldPlay) {
      incomingAudio.pause();
    }
  };

  const declinedAudio = new Audio(DeclinedAudio);
  declinedAudio.onpause = () => {
    if (shouldPlay) {
      safePlay(declinedAudio);
    }
  };
  declinedAudio.onplay = () => {
    if (!shouldPlay) {
      declinedAudio.pause();
    }
  };

  const ringingAudio = new Audio(AudioSrc);
  ringingAudio.loop = true;
  ringingAudio.onplay = () => {
    if (!shouldPlay) {
      ringingAudio.pause();
    }
  };
  ringingAudio.onpause = () => {
    if (shouldPlay) {
      safePlay(ringingAudio);
    }
  };
  sounds = {
    incoming: incomingAudio,
    ringing: ringingAudio,
    declined: declinedAudio,
  };
}

const handlePlaySound = (sound: CallSound | '') => {
  const doPlay = () => {
    shouldPlay = sound !== '';
    if (sound !== 'incoming' && sounds?.incoming) {
      sounds.incoming.pause();
    }
    if (sound !== 'ringing' && sounds?.ringing) {
      sounds.ringing.pause();
    }

    if (sound !== 'declined' && sounds?.declined) {
      sounds.declined.pause();
    }

    if (sounds && sounds[sound]) {
      safePlay(sounds[sound]);
    }
  };
  doPlay();
};

const Calls = ({
  sceytClient,
  hideActiveCall,
  setHideActiveCall,
}: {
  sceytClient: any;
  hideActiveCall: boolean;
  setHideActiveCall: (callName: string) => void;
}) => {
  // const [showCreateChannel, setShowCreateChannel] = useState(false)
  const [callInvitation, setCallInvitation] = useState<any>(null);
  const callInvitationRef = useRef(callInvitation);
  const [showAddUserPopup, setShowAddUserPopup] = useState<
    'audio' | 'video' | null
  >(null);
  const [activeCall, setActiveCall] = useState<any>(null);
  const activeCallRef = useRef(activeCall);
  const [showIncomingCallPopup, setShowIncomingCallPopup] =
    useState<any>(false);
  const [showCallPopup, setShowCallPopup] = useState<any>(false);
  const [callParticipants, setCallParticipants] = useState<any>([]);
  const callParticipantsRef = useRef<any>(callParticipants);
  const [callName, setCallName] = useState('');
  const [isDeclined, setIsDeclined] = useState(false);
  const [declineMessage, setDeclineMessage] = useState('');
  const [callAvatar, setCallAvatar] = useState('');
  const [isRinging, setIsRinging] = useState(false);
  const [screenSharing, setScreenSharing] = useState(false);
  const callClient = useRef<SceytCallClient | null>(null);
  const audioRef = useRef<any>(null);

  const [originatorId, setOriginatorId] = useState('');
  const [_, setCurrentCallActiveChannelId] = useState('');
  const [callType, setCallType] = useState('Voice');
  const [callTime, setCallTime] = useState<{ min: number; sec: number }>({
    min: 0,
    sec: 0,
  });

  const handleParticipantJoined = async (call: any, participant: any) => {
    console.log('Received participantJoined: ', participant);
    if (call.id === activeCallRef.current.id) {
      const callParticipant = callParticipantsRef.current.find(
        (user: any) => user.id === participant.id
      );
      if (!callParticipant) {
        const users = await sceytClient.getUsers([participant.id]);
        const updatedParticipants = [
          ...callParticipantsRef.current,
          {
            ...participant,
            firstName: users[0].firstName,
            lastName: users[0].lastName,
          },
        ];
        callParticipantsRef.current = updatedParticipants;
        setCallParticipants(updatedParticipants);
      }
    }
  };

  const handleParticipantLeft = (call: any, participant: any) => {
    if (call && activeCallRef.current && call.id === activeCallRef.current.id) {
      const updatedParticipants = callParticipantsRef.current.filter(
        (user: any) => user.id !== participant.id
      );
      callParticipantsRef.current = updatedParticipants;
      setCallParticipants(updatedParticipants);
    }
  };

  // @ts-ignore
  const handleParticipantAudioTrackAdded = (call: any, participant: any) => {
    console.log('Received participantAudioTrackAdded: ', participant);
    if (activeCallRef.current && call.id === activeCallRef.current.id) {
      const updatedParticipants = callParticipantsRef.current.map(
        (user: any) => {
          if (user.id === participant.id) {
            return { ...user, ...participant };
          } else {
            return user;
          }
        }
      );
      callParticipantsRef.current = updatedParticipants;
      setCallParticipants(updatedParticipants);
    }
  };

  // @ts-ignore
  const handleParticipantVideoTrackAdded = (
    // call: any,
    participant: any
    // videoTrack: any
  ) => {
    console.log('Received participantVideoTrackAdded: ', participant);
    const updatedParticipants = callParticipantsRef.current.map((user: any) => {
      if (user.id === participant.id) {
        return { ...user, ...participant };
      } else {
        return user;
      }
    });
    callParticipantsRef.current = updatedParticipants;
    setCallParticipants(updatedParticipants);
  };

  const handleParticipantEvent = (
    call: any,
    participant: any,
    participantEvent: ParticipantEvent
  ) => {
    console.log('Received participantEvent: ', call, participantEvent);
    if (activeCallRef.current && call.id === activeCallRef.current.id) {
      const updatedParticipants = callParticipantsRef.current.map(
        (user: any) => {
          if (user.id === participant.id) {
            return { ...user, ...participant };
          } else {
            return user;
          }
        }
      );
      if (participantEvent === 'ScreenSharingStarted') {
        setScreenSharing(true);
      } else if (participantEvent === 'ScreenSharingStopped') {
        setScreenSharing(false);
      }
      callParticipantsRef.current = updatedParticipants;
      setCallParticipants(updatedParticipants);
    }
  };
  const handleCallStateChanged = (call: any, reason: string) => {
    console.log('Received callStateChanged: ', reason);
    if (
      callInvitationRef.current &&
      call.id === callInvitationRef.current.id &&
      reason === 'closed'
    ) {
      setCallInvitation(null);
      setShowIncomingCallPopup(false);
    } else if (
      activeCallRef.current &&
      call.id === activeCallRef.current.id &&
      reason === 'closed'
    ) {
      setActiveCall(null);
      setScreenSharing(false);
      setShowCallPopup(false);
      setIsRinging(false);
    }
  };
  const handleParticipantRejectedCall = (
    call: any,
    participant: any,
    reason: string
  ) => {
    console.log('Received ParticipantRejectedCall: ', participant, reason);
    setDeclineMessage(reason);
    setIsDeclined(true);
    setIsRinging(false);
    if (
      callInvitationRef.current &&
      call.id === callInvitationRef.current.id &&
      reason === 'closed'
    ) {
    } else if (activeCallRef.current && call.id === activeCallRef.current.id) {
    }
  };

  const handleParticipantStateChange = (
    call: any,
    participant: any,
    state: string
  ) => {
    console.log('Received participantStateChanged: ', participant, state);
    if (activeCallRef.current && call.id === activeCallRef.current.id) {
      if (
        callParticipantsRef.current &&
        callParticipantsRef.current.length > 0
      ) {
        const updatedParticipants = callParticipantsRef.current.map(
          (user: any) => {
            if (user.id === participant.id) {
              return { ...user, state };
            } else {
              if (user.id === sceytClient.user.id && state !== 'connected') {
                return { ...user, state: 'connected' };
              } else {
                return user;
              }
            }
          }
        );
        callParticipantsRef.current = updatedParticipants;
        setCallParticipants(updatedParticipants);
      }
    }
  };

  const handleAddListeners = (call: any) => {
    call.onParticipantJoined = handleParticipantJoined;
    call.onParticipantLeft = handleParticipantLeft;
    call.onParticipantVideoTrackAdded = handleParticipantVideoTrackAdded;
    call.onParticipantAudioTrackAdded = handleParticipantAudioTrackAdded;
    call.onParticipantStateChanged = handleParticipantStateChange;
    call.onParticipantEvent = handleParticipantEvent;
    call.onCallStateChanged = handleCallStateChanged;
    call.onParticipantRejectedCall = handleParticipantRejectedCall;
  };
  const handleJoinCall = async (callOptions: any, isAnswer?: boolean) => {
    if (isAnswer) {
      handleAddListeners(callOptions);
    }
    const joinedCall = await callClient.current.joinCall({
      callId: callOptions.id,
      sessionId: callOptions.sessionId,
      mediaFlow: callOptions.mediaFlow,
      participantIds: isAnswer
        ? callOptions.participants.map((p: any) => p.id)
        : callOptions.participantIds,
      videoCall: callOptions.videoEnabled,
    });
    if (!isAnswer) {
      handleAddListeners(joinedCall);
    }
    setActiveCall(joinedCall);
    setShowCallPopup(true);
    return joinedCall;
  };
  const handleStartCallWithParticipants = async (
    participants: any[],
    videoCall?: boolean
  ) => {
    console.log('handle start call . with participants. .. ', participants);
    const activeChannel = getActiveChannel();
    const participantsIds = participants.map((member: any) => member.id);
    const callType = activeChannel.type === 'direct' ? 'p2p' : 'sfu';
    const callOptions = {
      mediaFlow: callType,
      participantIds: [...participantsIds, sceytClient.user.id],
      videoEnabled: videoCall,
    };
    const joinedCall = await handleJoinCall(callOptions);
    await handleGetCallInfo(joinedCall);
    setIsRinging(true);
    setActiveCall(joinedCall);
  };

  const handleStartCall = async (videoCall?: boolean) => {
    console.log('handle start call ... ');
    setDeclineMessage('');
    const activeChannel = getActiveChannel();
    setCurrentCallActiveChannelId(activeChannel.id);
    setOriginatorId(sceytClient._user.id);
    setCallType(videoCall ? 'Video' : 'Voice');

    // const userId = client.user.id
    if (activeChannel.memberCount > 9) {
      setShowAddUserPopup(videoCall ? 'video' : 'audio');
    } else {
      let participantsIds = [];
      if (activeChannel.memberCount <= 2) {
        participantsIds = activeChannel.members.map((member: any) => member.id);
      } else {
        const membersQueryBuilder = new sceytClient.MemberListQueryBuilder(
          activeChannel.id
        );
        membersQueryBuilder
          .all()
          .byAffiliationOrder()
          .orderKeyByUsername()
          .limit(50);
        const memberQuery = await membersQueryBuilder.build();
        const { members } = await memberQuery.loadNextPage();
        participantsIds = members.map((member: IMember) => member.id);
      }
      const callType = activeChannel.type === 'direct' ? 'p2p' : 'sfu';
      const callOptions = {
        mediaFlow: callType,
        participantIds: participantsIds,
        videoEnabled: videoCall,
      };
      const joinedCall = await handleJoinCall(callOptions);
      await handleGetCallInfo(joinedCall);
      setIsRinging(true);
      setIsDeclined(false);
      setActiveCall(joinedCall);
    }
    // const call = await callClient.current.joinCall(callOptions)
  };
  const answerCall = async (action: string) => {
    if (action === 'answer') {
      if (callInvitation) {
        await handleJoinCall(callInvitation, true);
        setCallInvitation(null);
        setShowIncomingCallPopup(false);
      }
    } else {
      await callClient.current.rejectCall(callInvitation, 'busy');
      setCallInvitation(null);
      setShowIncomingCallPopup(false);
    }
  };
  const handleLeaveCall = async () => {
    await callClient.current.leaveCall(activeCall);
    setCallInvitation(null);
    setActiveCall(null);
    setScreenSharing(false);
    setShowCallPopup(false);
    setIsRinging(false);
    setIsDeclined(false);
    setCallParticipants([]);
    callParticipantsRef.current = [];
    if (originatorId === sceytClient._user.id) {
      const callMetadata = {
        type: callType.toLowerCase() === 'voice' ? 1 : 2,
        duration: callTime?.sec
          ? `${String(callTime.min).padStart(2, '0')}:${String(
              callTime.sec
            ).padStart(2, '0')}`
          : '',
        status: !callTime?.sec ? 2 : 1,
      };

      console.log('call metadata', callMetadata);
    }
  };

  const handleGetCallStats = async () => {
    const sendMonitoringReports = getClientOptions()?.sendMonitoringReports;
    const monitoringApiBase = getItem('vtCallMonitoringAPIBase');
    if (
      activeCall?.id &&
      activeCall?.state === 'connected' &&
      sendMonitoringReports &&
      monitoringApiBase
    ) {
      const stats = await activeCall?.getStats();
      console.log('STATS RECEIVED: ', stats);
      const response = await axios.post(monitoringApiBase, stats);
      console.log('STATS SENT: ', response);
    }
  };

  // Get call stats every 10 seconds
  useEffect(() => {
    if (activeCall?.state === 'connected' && activeCall?.mediaFlow === 'p2p') {
      const interval = setInterval(handleGetCallStats, 10000);
      return () => clearInterval(interval);
    }

    return () => {};
  }, [activeCall, activeCall?.state]);

  const handleGetCallInfo = async (call: any) => {
    const participantsMap = {};
    const participantsIds = call.participants.map((participant: any) => {
      // @ts-ignore
      participantsMap[participant.id] = participant;
      return participant.id;
    });
    if (call.mediaFlow === 'p2p') {
      sceytClient.getUsers(participantsIds).then((users: any) => {
        const updatedParticipants = users.map((user: any) => ({
          // @ts-ignore
          ...participantsMap[user.id],
          avatarUrl: user.avatarUrl,
          firstName: user.firstName,
          lastName: user.lastName,
        }));

        const orderedParticipants = makeFirstById(
          updatedParticipants,
          sceytClient.user.id
        );
        callParticipantsRef.current = orderedParticipants;
        setCallParticipants(orderedParticipants);
        // setP2pUser([users[0]])
        const participantUser = users.find(
          (user: any) => user.id !== sceytClient.user.id
        );
        if (participantUser) {
          setCallAvatar(participantUser.avatarUrl);
          setCallName(
            participantUser.firstName
              ? `${participantUser.firstName} ${participantUser.lastName}`
              : participantUser.id
          );
        }
      });
    } else {
      console.log('get users .. >> 1');
      sceytClient.getUsers(participantsIds).then((users: any) => {
        console.log(' users  received . >> ', users);
        const updatedParticipants = users.map((user: any) => ({
          // @ts-ignore
          ...participantsMap[user.id],
          avatarUrl: user.avatarUrl,
          firstName: user.firstName,
          lastName: user.lastName,
        }));
        const orderedParticipants = makeFirstById(
          updatedParticipants,
          sceytClient.user.id
        );
        callParticipantsRef.current = orderedParticipants;
        setCallParticipants(orderedParticipants);
        const filteredParticipant: string[] = users.filter(
          (user: any) => user.id !== sceytClient.user.id
        );
        if (call.metadata && call.metadata.csb) {
          setCallName(call.metadata.csb);
        } else if (users && users.length > 0) {
          setCallName(
            filteredParticipant
              .map((user: any) => user.firstName || user.id)
              .join(', ')
          );
        }
      });
    }
  };
  const handleInvitedToCall = async (call: any) => {
    if (!activeCall && call) {
      await handleGetCallInfo(call);
      call.onCallStateChanged = handleCallStateChanged;
      setCallInvitation(call);
    }
  };

  useDidUpdate(() => {
    if (isRinging) {
      handlePlaySound('ringing');
    } else {
      // source.stop()
      handlePlaySound('');
    }
  }, [isRinging]);

  useDidUpdate(() => {
    if (isDeclined) {
      handlePlaySound('');
    } else {
      // source.stop()
      handlePlaySound('');
    }
  }, [isDeclined]);

  useDidUpdate(() => {
    if (showIncomingCallPopup) {
      handlePlaySound('incoming');
    } else {
      setCallAvatar('');
      handlePlaySound('');
    }
  }, [showIncomingCallPopup]);

  useEffect(() => {
    initializeSounds();
    if (sceytClient) {
      const callClientInstance = new SceytCallClient(sceytClient);
      callClientInstance.onInvitedToCall = handleInvitedToCall;
      callClient.current = callClientInstance;
    }
  }, [sceytClient]);

  useDidUpdate(() => {
    callInvitationRef.current = callInvitation;
    if (callInvitation) {
      setShowIncomingCallPopup(true);
    }
  }, [callInvitation]);

  useDidUpdate(() => {
    activeCallRef.current = activeCall;
  }, [activeCall]);

  useDidUpdate(() => {
    if (callParticipants.length === 1 && !isDeclined && !isRinging) {
      handleLeaveCall();
    }
  }, [callParticipants, isDeclined, isRinging]);
  return (
    <React.Fragment>
      {/*@ts-ignore*/}
      <audio ref={audioRef} muted>
        <source src='../../../../assets/phone-ringing.mp3' type='audio/mp3' />
      </audio>

      {!activeCall && (
        <CallButtons>
          <VideoCallButton onClick={() => handleStartCall(true)}>
            <VideoCallIcon />
          </VideoCallButton>
          <VoiceCallButton onClick={() => handleStartCall(false)}>
            <VoiceCallIcon />
          </VoiceCallButton>
        </CallButtons>
      )}

      {showIncomingCallPopup && (
        <IncomingCallPopup
          handleAnswerCall={answerCall}
          call={callInvitation}
          callName={callName}
          callAvatar={callAvatar}
          callUsers={callParticipants}
        />
      )}
      {showCallPopup && (
        <CallPopup
          setCallTimeHandler={setCallTime}
          handleStartCall={handleStartCall}
          declineMessage={declineMessage}
          isDeclined={isDeclined}
          setIsRinging={setIsRinging}
          leaveCall={handleLeaveCall}
          call={activeCall}
          callUsers={callParticipants}
          callName={callName}
          screenSharing={screenSharing}
          sceytClient={sceytClient}
          hideActiveCall={hideActiveCall}
          setHideActiveCall={setHideActiveCall}
          isVideoCall={activeCall?.videoEnabled}
        />
      )}
      {showAddUserPopup && (
        <UsersPopup
          memberIds={[sceytClient.user.id]}
          submitHandler={handleStartCallWithParticipants}
          channel={getActiveChannel()}
          sceytClient={sceytClient}
          isVideoCall={showAddUserPopup === 'video'}
          maxSelectionCount={8}
          toggleCreatePopup={() => setShowAddUserPopup(null)}
          actionType='startCall'
          popupHeight='540px'
          popupWidth='520px'
          popupTitleText={'Add to call'}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(Calls, (prevProps, nextProps) => {
  return (
    prevProps.sceytClient === nextProps.sceytClient,
    prevProps.hideActiveCall === nextProps.hideActiveCall
  );
});

const CallButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const VoiceCallButton = styled(DefaultButton)`
  display: flex;
  padding: 0 8px;

  & svg {
    color: #818c99;
  }
`;

const VideoCallButton = styled(DefaultButton)`
  display: flex;
  padding: 0 8px;

  & svg {
    color: #818c99;
  }
`;
