import styled from 'styled-components'
import {ReactComponent as CloseSvg} from '../assets/svg/close.svg'
import {colors} from "./constants";

export const CustomUl = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`

export const CustomLi = styled.li<{
    textColor?: string
    hoverBackground?: string
    iconWidth?: string
    iconColor?: string
    margin?: string
}>`
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 20px;
    color: ${(props: any) => props.textColor || colors.blue1};
    margin: ${(props: any) => props.margin};
    padding: 6px 6px 6px 16px;
    transition: all .2s;

    &:hover {
        background: ${(props) => props.hoverBackground || colors.gray0};
    }

    & > svg {
        width: ${(props) => props.iconWidth};
        min-width: ${(props) => props.iconWidth};
        height: ${(props) => props.iconWidth};
        color: ${(props) => props.iconColor};
        margin-right: 10px;
    }
`

export const Label = styled.label<{ color?: string }>`
    display: inline-block;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    margin-top: 20px;
    margin-bottom: 4px;
    color: ${props => props.color || colors.gray6};
`

export const CustomInput = styled.input<{ error?: boolean }>`
    position: relative;
    height: 40px;
    width: 100%;
    background: #ffffff;
    border: ${(props) => (props.error ? `1px solid ${colors.red1}` : '1px solid #ededed')};
    box-sizing: border-box;
    border-radius: 8px;
    padding: 11px 14px;
    font-family: Inter, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    opacity: 1;
    outline: none;

    &:focus {
        border: 1px solid ${(props) => (props.error ? `1px solid ${colors.red1}` : colors.green1)};
        outline: 2px solid ${(props) => (props.error ? `1px solid ${colors.red2}` : '#ebf7f1')};
    }

    &:disabled {
        background-color: ${colors.gray0};
        opacity: 1;
        color: #383b51;
    }

    &::placeholder {
        opacity: 1;
        color: ${colors.gray6};
    }
`

export const InputErrorMessage = styled.p`
    position: absolute;
    left: 0;
    bottom: -16px;
    font-size: 12px;
    color: ${colors.red1};
    margin: 4px 0 0;
`

export const InputGroup = styled.div`
    position: relative;
`

export const Button = styled.button<{
    color?: string
    backgroundColor?: string
    borderRadius?: string
    disabled?: boolean
    margin?: string
}>`
    display: inline-block;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    cursor: ${(props) => !props.disabled && 'pointer'};
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    border-radius: ${(props) => props.borderRadius || '4px'};
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    padding: 8px 16px;
    background-color: ${(props) => props.backgroundColor || colors.white};
    color: ${(props) => props.color || (props.backgroundColor ? colors.white : colors.gray6)};
    border: 1px solid ${(props) => props.backgroundColor || colors.gray2};
    margin: ${(props) => props.margin || '0'};
    user-select: none;
    transition: opacity 0.1s;
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};

    &:hover,
    &:focus {
        opacity: ${(props) => (props.disabled ? 0.5 : 0.8)};
    }
`
export const ButtonBlock = styled.div<{ marginTop?: string; paddingRight?: string; justify?: string; height?: string; backgroundColor?: string }>`
    display: flex;
    align-items: center;
    margin-top: ${(props) => props.marginTop || '10px'};
    padding-right: ${(props) => props.paddingRight || '0px'};
    justify-content: ${(props) => props.justify || 'flex-end'};
    height: ${(props) => props.height};
    min-height: ${(props) => props.height};
    background-color: ${(props) => props.backgroundColor};

    a {
        width: 88px;
        height: 40px;
        margin: 0 4px;
        font-size: 15px;
        font-weight: 500;
    }
`

export const PopupContainer = styled.div<{width?: string; height?: string}>`
    direction: initial;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.4);
`

export const Popup = styled.div<{
    minWidth?: string;
    maxWidth?: string;
    maxHeight?: string;
    width?: string;
    height?: string;
    display?: string
    justifyContent?: string
    padding?: string
    backgroundColor?: string
    borderRadius?: string
    color?: string
}>`
    position: relative;
    min-height: 150px;
    min-width: ${(props) => props.minWidth || '400px'};
    max-width: ${(props) => props.maxWidth || '600px'};
    max-height: ${(props) => props.maxHeight || '650px'};
    width: ${(props) => props.width || 'unset'};
    height: ${(props) => props.height || 'unset'};
    display: ${(props) => props.display || 'flex'};
    flex-direction: column;
    justify-content: ${(props) => props.justifyContent};
    padding: ${(props) => (props.padding ? props.padding : '22px 24px')};
    background: ${(props) => props.backgroundColor || colors.white};
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.06);
    border-radius: ${(props) => props.borderRadius || '8px'};
    box-sizing: border-box;
    transition: all 0.3s;
    ${(props: any) =>
            props.isLoading &&
            `
        user-select: none;

        & > * {
           pointer-events: none;
           user-select: none;
        }

         ${ButtonBlock} {
          a, button {
            pointer-events: none;
            user-select: none;
            opacity: 0.7;
          }
        }
    `};
`

export const CallPopupWrapper = styled(Popup)<{ minimize?: boolean }>`
    position: fixed;
    overflow: hidden;
    ${props => props.minimize ? `
        flex-direction: row;
        flex-wrap: wrap;
        cursor: move;
        animation: toMiniScreen 0.4s ease-in-out;
            left: 20px;
            bottom: 100px;
    ` : `
    animation: toNormalScreen 0.4s ease-in-out;
    `};
    &.react-draggable-dragging {
        transition: initial;
    }
    @keyframes toNormalScreen {
        from {
            left: 20px;
            bottom: 100px;
            width: 320px;
            height: 320px;
        }
        to {
            left: calc(50% - 500px);
            bottom: calc(50% - 360px);
            width: 1000px;
            height: 720px;
        }
    }
    @keyframes toMiniScreen {
        from {
            left: calc(50% - 500px);
            bottom: calc(50% - 360px);
            width: 1000px;
            height: 720px;
        }
        to {
            left: 20px;
            bottom: 100px;
            width: 320px;
            height: 320px;
        }
    }
`
export const PopupBody = styled.div<{ padding: number; withFooter?: boolean, textCenter?: boolean }>`
    padding: ${(props) => `${props.padding}px`};
    height: ${(props) => (props.withFooter ? `calc(100% - (54px + ${props.padding}px))` : 'calc(100% - 54px)')};
    text-align: ${props => props.textCenter && 'center'};
`

export const PopupDescription = styled.p<{ color?: string, marginTop?: string, marginBottom?: string }>`
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    color: ${props => props.color || colors.blue2};
    cursor: default;
    white-space: pre-line;
    margin-top: ${(props) => props.marginTop || '10px'};
    margin-bottom: ${(props) => props.marginBottom || '10px'};
    word-break: break-word;

    .highlight {
        text-decoration: underline;
        font-weight: 500;
        color: ${colors.blue1};
    }
`

export const PopupFooter = styled(ButtonBlock)`
    margin-top: ${(props) => props.marginTop || '0'};
    padding: 8px 16px;
    border-radius: 0 0 8px 8px;
`

export const SectionHeader = styled.h4<{ margin?: string }>`
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: ${colors.textColor1};
    margin: ${(props) => props.margin || 0};
`
export const SectionSubHeader = styled.h4<{ margin?: string }>`
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: ${colors.textColor1};
    margin: ${(props) => props.margin || 0};
`

export const PopupName = styled.h3<{
    marginTop?: string
    marginBottom?: string
    isDelete?: boolean
}>`
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    color: ${colors.blue1};
    margin: 0;
    margin-top: ${(props: any) => props.marginTop};
    margin-bottom: ${(props: any) => props.marginBottom};
    word-break: break-word;

    ${(props: any) => {
        if (props.isDelete) {
            return `
            max-width: calc(100% - 20px);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        `
        }

        return ''
    }}
`
export const CloseIcon = styled(CloseSvg)`
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
    padding: 15px;
`
export const ItemNote = styled.div<{ direction: string }>`
    display: none;
    position: absolute;
    z-index: 301;
    padding: 10px 12px;
    background-color: ${colors.textColor1};
    border-radius: 12px;
    font-size: 0.75rem;
    white-space: nowrap;
    font-weight: 600;
    color: white;
    pointer-events: none;
    user-select: none;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        background-color: ${colors.textColor1};
        border-radius: 3px;
        width: 14px;
        height: 14px;

        ${(props: any) =>
                props.direction === 'right' &&
                `
            left: -5px;
            top: 50%;
            transform: translateY(-50%) rotate(45deg);
        `} ${(props: any) =>
                props.direction === 'top' &&
                `
            bottom: -5px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
        `}
    }

    ${(props: any) =>
            props.visible &&
            `
       display: block;
    `} ${(props: any) =>
            props.direction === 'right' &&
            `
        top: 50%;
        left: calc(100% + 15px);
        transform: translateY(-50%);
    `} ${(props: any) =>
            props.direction === 'top' &&
            `
        bottom: calc(100% + 15px);
        left: 50%;
        transform: translateX(-50%);
    `} ${(props: any) =>
            props.disabled &&
            `
        color: ${colors.textColor2};
    `}
`

export const DefaultButton = styled.button`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`
export const CustomSelect = styled.div<{
    isError?: boolean
    minWidth?: string
    maxWidth?: string
    marginTop?: string
    backgroundColor?: string
    color?: string
}>`
    display: flex;
    height: 40px;
    min-height: 40px;
    width: 100%;
    min-width: ${(props) => props.minWidth};
    max-width: ${(props) => props.maxWidth};
    background: ${(props) => props.backgroundColor || colors.white};
    border: ${(props) => (props.isError ? `1px solid ${colors.red1}` : `1px solid ${colors.gray1}`)};
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => props.color || colors.textColor1};
    margin-top: ${(props) => props.marginTop};

    ::placeholder {
        color: ${colors.textColor2};
    }

    &:disabled {
        background-color: ${colors.gray1};
    }

    .dropdown-wrapper {
        width: 100%;
    }

    .dropdown-body {
        width: 100%;
    }

    .dropdown-trigger {
        & .default-selection {
            color: ${colors.textColor1};
        }

        //width: calc(100% - 20px);
    }
`
export const MessageOwner = styled.h3<{
    color?: string
    rtlDirection?: boolean
    fontSize?: string
    clickable?: boolean
}>`
    margin: 0 12px 4px 0;
    white-space: nowrap;
    color: ${(props) => props.color || colors.primary};
    margin-left: ${(props) => props.rtlDirection && 'auto'};
    font-weight: 500;
    font-size: ${(props) => props.fontSize || '15px'};
    line-height: ${(props) => props.fontSize || '18px'};
    cursor: ${(props) => props.clickable && 'pointer'};
    overflow: hidden;
    text-overflow: ellipsis;
`
export const MessageText = styled.pre<{
    fontFamily?: string
    color?: string
    withAttachment?: boolean
    fontSize?: string
    lineHeight?: string
    showMessageSenderName?: boolean
    isRepliedMessage?: boolean
    withMediaAttachment?: boolean
    isForwarded?: boolean
    withPaddings?: boolean
}>`
    display: flow-root;
    position: relative;
    font-family: ${(props) => props.fontFamily || 'sans-serif'};
    margin: 0;
    padding: ${(props) =>
            props.withAttachment &&
            (props.showMessageSenderName
                    ? props.withPaddings
                            ? '0 12px 10px'
                            : '0 0 10px'
                    : props.isForwarded
                            ? props.withPaddings
                                    ? '4px 12px 10px'
                                    : '4px 0px 10px'
                            : '8px 12px 10px')};
    padding-bottom: ${(props) => props.withAttachment && !props.withMediaAttachment && '2px'};
        //font-size: ${(props) => props.fontSize || '15px'};
    font-size: ${(props) => props.fontSize || '16px'};
    line-height: ${(props) => props.lineHeight || '20px'};
    font-weight: 400;
    word-wrap: break-word;
    white-space: pre-wrap;
    //white-space: normal;
    //letter-spacing: -0.2px;
    letter-spacing: 0.3px;
    color: ${(props) => props.color || colors.textColor1};
    user-select: text;
    //overflow: hidden;

    ${(props) =>
            props.isRepliedMessage &&
            `
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
  `}
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
    }

    & a {
        color: ${colors.blue3};
    }
`